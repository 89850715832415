import React, { useContext, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import { Card } from '@mui/material';
import RegistrationStepper from './RegistrationStepper';
import ProgressBar from '../../ProgressBar';
import { ProfileStrengthContext } from '../../../context/ProfileStrengthContext';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { CandidateContext } from '../../../context/CandidateContext';
import GlobalLoader from '../../GlobalLoader';

function UpdateStepper() {
  const { strength } = useContext(ProfileStrengthContext)
  const { profileStrength } = useContext(CandidateContext)
  const { redirectionLink } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if(profileStrength >= 65) {
      history.push(redirectionLink)
    }
  }, [history, profileStrength, redirectionLink])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingX: { xs: '0px !important', sm: "20px !important", md: '40px !important' }, paddingTop: {xs: '40px !important'}, paddingBottom: {xs: '60px !important'} }}>
      <Container>
        {!profileStrength ? 
        <GlobalLoader height="60vh" />
        :
        <Card sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: "unset",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
          backgroundColor: "#FFFFFF",
          borderRadius: '10px',
        }}
        >
          <Box sx={{ margin: {xs: "24px", md: "48px 48px"}, display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Epilogue", fontWeight: 600, fontSize: {xs: "16px", md: "20px", lg: "24px"}, color: "#424447", lineHeight: "20px"}}>
            To apply for your dream job, please complete your profile
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ProgressBar profile_percent={strength} />
          </Box>

          <RegistrationStepper />

        </Card>
        }
      </Container>
    </Box >
  )
}

export default UpdateStepper;