import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Divider,
  Box,
  Typography,
  Button,
  IconButton,
  Skeleton,
} from "@mui/material";
import Select from 'react-select'
import { LoadingButton } from "@mui/lab";
import { DropdownContext } from "../../../context/DropdownContext";
import { candidateRegisterFlow, stepperStepInfo } from "../../../../fetchAPI";
import { fetchIpAddress, handleKeyDown } from "../../../helpers/helperFunctions";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15 "
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M0 18.4731C0.438118 16.7379 0.874932 15.002 1.31044 13.2655C1.35415 13.0819 1.4491 12.9145 1.58427 12.7828C3.95349 10.4209 6.31685 8.05581 8.67435 5.68743C10.5259 3.84145 12.3736 1.99156 14.2173 0.137764C14.3992 -0.0459215 14.3992 -0.0459215 14.5831 0.137764L18.3208 3.87597C18.3794 3.93655 18.444 3.99322 18.5164 4.05185V4.12609L18.3442 4.29024L5.69162 16.9431C5.59254 17.0497 5.46492 17.1256 5.32392 17.1619C4.48484 17.3573 3.64968 17.5743 2.81256 17.7833L0.111485 18.4653L0 18.4731ZM2.83799 15.7374L2.94556 15.71C3.37781 15.6026 3.81006 15.4873 4.24427 15.3915C4.41728 15.3545 4.57529 15.2668 4.69803 15.1394C5.93546 13.8888 7.17418 12.6408 8.41421 11.3954C10.8043 9.01396 13.1911 6.63387 15.5747 4.25507C15.6999 4.13001 15.7018 4.05965 15.5747 3.93459C15.2266 3.60239 14.8843 3.26043 14.5518 2.91064C14.4305 2.78363 14.3562 2.7973 14.2467 2.91064C13.3795 3.79781 12.5085 4.67519 11.6336 5.54282C8.86798 8.31244 6.1004 11.0795 3.33087 13.8439C3.22469 13.9444 3.15111 14.0744 3.11963 14.2171C3.04531 14.5513 2.95534 14.8815 2.87124 15.2117C2.83603 15.3544 2.79887 15.497 2.76562 15.6397C2.75193 15.6983 2.74802 15.755 2.83799 15.7413V15.7374Z"
        fill="#0275D8"
      />
    </svg>
  );
}

function DeleteIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.101562" width="20" height="20" rx="10" fill="#FFDCDC"/>
    <path d="M7.91558 12.2213C7.40854 12.7284 6.90288 13.234 6.39585 13.7411C6.08477 14.0521 6.08203 14.4496 6.37392 14.725C6.47572 14.8227 6.60749 14.8833 6.74797 14.897C6.88844 14.9107 7.02943 14.8766 7.14817 14.8004C7.23379 14.7413 7.31363 14.6744 7.38661 14.6003L10.3137 11.6732C10.5289 11.458 10.4713 11.458 10.6947 11.6787C11.6841 12.6681 12.6735 13.6575 13.6629 14.6469C13.9863 14.9703 14.3823 14.9826 14.6619 14.6894C14.7604 14.5885 14.822 14.4574 14.8369 14.3172C14.8518 14.177 14.8191 14.0358 14.7441 13.9165C14.6863 13.8299 14.6197 13.7496 14.5454 13.6767C13.556 12.6873 12.5652 11.6965 11.5758 10.7071C11.4538 10.5851 11.4525 10.5235 11.5758 10.4029C12.5652 9.41347 13.5546 8.42407 14.5454 7.43329C14.6157 7.3658 14.679 7.29143 14.7345 7.2113C14.8144 7.08734 14.8495 6.93973 14.8338 6.79307C14.8182 6.6464 14.7528 6.50952 14.6485 6.40522C14.5442 6.30092 14.4073 6.2355 14.2606 6.21985C14.114 6.20419 13.9664 6.23925 13.8424 6.31919C13.7566 6.37794 13.6771 6.44545 13.6053 6.52063L10.6919 9.43402C10.4672 9.65876 10.5302 9.65876 10.2986 9.42717C9.32842 8.45696 8.35729 7.48583 7.38524 6.51378C7.31774 6.44349 7.24337 6.38014 7.16324 6.32467C7.03912 6.24201 6.89019 6.2049 6.74179 6.21963C6.59339 6.23436 6.45467 6.30003 6.34922 6.40549C6.24376 6.51094 6.17809 6.64966 6.16335 6.79806C6.14862 6.94647 6.18574 7.09539 6.2684 7.21952C6.32513 7.29864 6.38888 7.37248 6.45888 7.44014C7.44828 8.42955 8.43906 9.42032 9.42846 10.4097C9.54357 10.5248 9.54494 10.5865 9.42846 10.7002C8.92827 11.2086 8.42124 11.7157 7.91558 12.2213Z" fill="#DB1616"/>
    </svg>
  );
}

const customStyles = {
  control: (base) => ({
    ...base,
    padding: "3px",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#A2A2A2",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "200px", 
    overflowY: "auto",
  }),
};

function CustomChip({ label, onDelete, onEdit }) {
  return (
    <Box
      sx={{
        maxWidth: 'fit-content !important',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F9F9F9',
        borderRadius: '24px',
        padding: '0px 0px 0px 12px',
        cursor: 'pointer',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Label */}
      <Typography
        variant="body2"
        sx={{
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          color: '#424447',
          marginRight: '8px',
        }}
      >
        {label}
      </Typography>

      {/* Delete Button */}
      {onEdit && (
        <IconButton
          onClick={onEdit}
        >
          <EditIcon />
        </IconButton>
      )}

      {/* Delete Button */}
      {onDelete && (
        <IconButton
          onClick={onDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default function SkillInformation({ handleBack, handleNext, job_id, candidateData, settingCandidateData, candLoader, setIsFillup }) {
  const {
    skills,
    setApiCallingConditionSet,
    apiCallingConditionSet,
  } = useContext(DropdownContext);
  const [tempSkillArray, setTempSkillArray] = useState([]);
  const [tempSkill, setTempSkill] = useState({ id: "" });
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [skillError, setSkillError] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filteredSkillOptions, setFilteredSkillOptions] = useState([]);

  useEffect(() => {
    setApiCallingConditionSet({
      ...apiCallingConditionSet,
      skill: true,
    });
  }, []);

  useEffect(() => {
    setTempSkillArray(candidateData?.skills);
  }, [candidateData]);

  useEffect(() => {
    if(
      tempSkill?.pivot?.year_of_experience > 50 || 
      tempSkill?.pivot?.year_of_experience < 0
    ){
      setSkillError(true);
    }else{
      setSkillError(false) 
    }
  }, [tempSkill?.pivot?.year_of_experience]);

  const addValueToArray = async () => {
    const isExists = tempSkillArray?.find((skill) => skill.id == tempSkill.id)
    if(skillError){
      const newArray = [];
      setTempSkillArray(newArray);
    }else{
      if(!isExists){
        const newArray = [...tempSkillArray, tempSkill];
        setTempSkillArray(newArray);
        setResponseMessage("");
      }else{
        setResponseMessage([`${isExists.skill_name} is already added.`])
      }
      }
  };

  const removeSkillFromTemp = (index) => {
    const newArray = [...tempSkillArray];
    newArray.splice(index, 1);
    setTempSkillArray(newArray);
  };

  const handleAddSkill = () => {
    setLoading(true);

    if(!!tempSkillArray.length) {
      setIsFillup((prev) => ({...prev, skill:true}))
    }

    candidateRegisterFlow("skill", job_id, tempSkillArray)
      .then((response) => {
        if (response && response.success) {
          handleNext();
        } else {
          setResponseMessage(response.errors[0]?.message);
        }
      })
      .finally(() => setLoading(false), setResponseMessage(""));

      const ip = fetchIpAddress();
      const platform = "web";
      const stepNo = 4;
  
      stepperStepInfo(stepNo, ip, platform).then((response) => {
        if(response && response.success) {
          setLoading(false);
        }
      })
  };

  const handleSkillSubmit = () => {
      addValueToArray()
      .then(() =>       
        setTempSkill({
        id: "",
        skill_name: "",
        pivot: { year_of_experience: "" }
    }));

  };

  const formatSkills = (data) => {
    return data?.map(skill => ({
        label: skill.skill_name,
        value: skill.id
    }));
}

const skillsOption = formatSkills(skills);

const handleInputChange = (inputValue) => {
  if (inputValue) {
      // Filter skills based on input and limit to 10 results
      const filtered = skills
          .filter((item) =>
              item.skill_name.toLowerCase().startsWith(inputValue.toLowerCase())
          )
          .slice(0, 10);
      setFilteredSkillOptions(filtered);
      setMenuIsOpen(true);
  } else {
      setFilteredSkillOptions([]); 
      setMenuIsOpen(false);
  }
};

const handleSkillSelect = (selectedOption) => {
  if (selectedOption) {
    setTempSkill({
      ...tempSkill,
      id: selectedOption.value,
      skill_name: selectedOption.label,
    });
  } else {
    setTempSkill({ id: "", skill_name: "" });
  }
};

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Typography align="left" variant="h6" sx={{ fontWeight: 600, fontSize: "16px", mb: 1 }}>
            Experience
        </Typography>
      </Box>

      <Box sx={{mx: -6}}>
          <Divider />
      </Box>

      {candLoader ? (
        <div className="mt-3 col-lg-12 col-md-12">
          <Skeleton variant="text" height={60} />
          <Skeleton variant="text" height={50} />
          <div className="text-left">
            <Skeleton variant="text" />
          </div>
          <div className="mt-3 text-left">
            <Skeleton variant="rounded" height={120} />
          </div>
          <div className="text-left">
            <Skeleton variant="text" width={80} height={50} />
          </div>
        </div>
      ) : (
        <>
          <Box sx={{marginX: {xs: -3, sm: 0}}}>
            <form
            className="ga_stepper_candidate_register_skill_info_form" // do not remove ga_ class
            id="skill-insert-form"
            onSubmit={(e) => [e.preventDefault(), handleSkillSubmit()]}
            >
              {responseMessage ? (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {responseMessage?.map((item, index) => {
                    return (
                      <center key={index}>
                        <small style={{ color: "red" }}> {responseMessage} </small>
                      </center>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              <Grid container spacing={2} mt={3}>
                <Grid item lg={6} md={5} xs={12}>
                    <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}> Skills </InputLabel>
                    <Select
                        placeholder="Select Skills"
                        options={filteredSkillOptions.map((item) => ({
                          label: item.skill_name,
                          value: item.id,
                        }))}
                        onInputChange={handleInputChange}
                        menuIsOpen={menuIsOpen}
                        styles={customStyles}
                        onChange={handleSkillSelect}
                        value={skillsOption.find(option => option.value === tempSkill.id) || null}
                        required  
                        isClearable
                      />
                </Grid>

                <Grid item xl={3.5} md={3} xs={12}>
                  <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}> Years of Experience </InputLabel>
                  <TextField
                    required
                    type="number"
                    variant="outlined"
                    placeholder="Number of Years"
                    fullWidth
                    error={skillError}
                    value={tempSkill?.pivot?.year_of_experience}
                    onChange={(e) =>
                        setTempSkill({
                          ...tempSkill,
                          pivot: { year_of_experience: e.target.value },
                        })
                    }
                    inputProps={{
                      style: {
                        padding: "10px 14px",
                      },
                      onKeyDown: (e) => handleKeyDown(e),
                    }}
                  />

                  <InputLabel sx={{fontSize: "13px", color: "red", pt: 0.5}}>{skillError ? "Years of experience should between 0 to 50" : null}</InputLabel>
                </Grid>

                <Grid item xl={2.5} lg={3} md={4} xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      paddingY: "9px",
                      fontFamily: "Epilogue",
                      fontWeight: 600,
                      fontSize: "14px",
                      backgroundColor: "#E3F2FF",
                      color: "#0079C1",
                      marginTop: {xs: "0px", md: "22px"},
                      textTransform: "capitalize",
                      maxWidth: {xs: "200px", md: "240px"},
                    }}
                    type="submit"
                  >
                    Save and Add Another
                  </Button>
                </Grid>

              </Grid>
            </form>

            <Box sx={{display: "flex", flexWrap: "wrap", gap: 2, mt: 2}}>
              {tempSkillArray?.map((item, index) => {
                return (
                    <CustomChip 
                      key={index}
                      label={`${item.skill_name} - ${item.pivot?.year_of_experience} Years`} 
                      onEdit={() => {setTempSkill(item); removeSkillFromTemp(index)}}
                      onDelete={() => removeSkillFromTemp(index)}
                    />        
                );
              })}
            </Box>
          

              <Box sx={{mx: {xs: -3, sm: -6}, paddingTop: "40px"}}>
                  <Divider />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 4 }}>
                  <Button 
                      variant="outlined" 
                      // disabled 
                      sx={{ 
                          mr: 1, 
                          padding: {xs: "10px 16px"}, 
                          minWidth: {sm: "102px"}, 
                          color: "#0079C1", 
                          fontFamily: "Epilogue", 
                          fontWeight: 600, 
                          fontSize: "14px", 
                          lineHeight: "16.5px", 
                          display: "flex", 
                          alignItems: "center", 
                          gap: 1, 
                          textTransform: "capitalize", 
                          // '&.Mui-disabled': {color: "#0079C1", borderColor: "#0079C1",} 
                          }}
                          onClick={handleBack}
                      >
                          <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.64706 1.10156L2 7.10156L7.64706 13.1016" fill="white"/>
                          <path d="M7.64706 1.10156L2 7.10156M2 7.10156L7.64706 13.1016M2 7.10156L26 7.10156" stroke="#0079C1" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          Back
                  </Button>
                  <Box sx={{ display: "flex" }}>
                      <LoadingButton
                          sx={{ ml: {xs: 1, md: 2}, padding: {xs: "10px 16px"}, minWidth: {sm: "102px"}, color: "#FFFDFD", fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "20.5px", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize" }}
                          variant="contained"
                          loading={loading}
                          onClick={handleAddSkill}
                      >
                          Continue
                          <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.3529 13.1016L26 7.10156M26 7.10156L20.3529 1.10156M26 7.10156L2 7.10156" stroke="white" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </LoadingButton>
                  </Box>
              </Box>  
          </Box>
        </>
      )}
    </>
  );
}
