import React, { useRef, useState, useEffect } from "react";
import {
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Box,
  Divider,
  Button,
  Typography,
  Select,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from "@material-ui/core/styles";
import {
  candidateRegisterFlow,
  getSalaryTypes,
  getTypes,
  stepperStepInfo,
} from "../../../../fetchAPI";
import { LoadingButton } from "@mui/lab";
import { Skeleton } from "@mui/material";
import { fetchIpAddress, handleKeyDown, validatePhoneNumber } from "../../../helpers/helperFunctions";
import { useAlert } from "../../../context/AlertContext";
import RequiredAsterisk from "../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk";
import ClearIcon from '@mui/icons-material/Clear'; 

const getDobMaxYear = () => {
  let d = new Date();
  d.setFullYear(d.getFullYear());
  return d.toJSON().slice(0, 10);
};

const useStyles = makeStyles({
  input: {
    borderLeft: '1px solid #C4C4C4',
    paddingLeft: "14px",
    marginLeft: "7px",
    padding: "30px 0px",
  },
  outlinedInput: {
    '& .react-tel-input .form-control': {
      borderRadius: '4px',
      borderColor: '#C4C4C4',
      padding: '14px',
      fontSize: '16px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .react-tel-input .flag-dropdown': {
      border: 'none',
      backgroundColor: 'transparent',
    },
    '& .react-tel-input .form-control:focus': {
      borderColor: '#0275D8',
      boxShadow: '0 0 0 1px #0275D8',
    },
    '& .react-tel-input .selected-flag': {
      marginLeft: '8px',
      marginRight: '12px',
    },
    '& .react-tel-input .country-list': {
      zIndex: 1000,
    },
  }
});

export default function PersonalInformation({ handleNext, job_id, candidateData, candLoader, settingCandidateData, setIsFillup }) {
  const [jobTypes, setJobTypes] = useState();
  const [salaryTypes, setSalaryTypes] = useState();
  const [salaryInfos, setSalaryInfos] = useState({
    candidate_type_id: null,
    salary_type_id: null,
    present_salary: null,
    expected_salary: null,
  })
  const fullNameRef = useRef("");
  const [gender, setGender] = useState("");
  const dobRef = useRef("");
  const [phoneNumber, setPhoneNumber] = useState("")
  const [country, setCountry] = useState("bd")
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [isBD, setIsBD] = useState(false);
  const alertContext = useAlert();
  const [dialCode, setDialCode] = useState("");

  useEffect(() => {
    if(candidateData?.dial_code && candidateData?.phone_no) {
      setPhoneNumber(candidateData?.dial_code + candidateData?.phone_no)
    }
  }, [candidateData.dial_code, candidateData.phone_no])

  const handlePhoneChange = (value, countryData) => {
    setDialCode(countryData.dialCode); 
    
    if(country !== countryData.countryCode) {
      setPhoneNumber('');
      setCountry(countryData.countryCode);
    }
    
    if(countryData.countryCode === "bd"){
      setIsBD(true)
    } else {
      setIsBD(false)
    }
    setPhoneNumber(value)
  };

  const handleSubmit = () => {
    let actualNumber;
    if(dialCode) {
      actualNumber = phoneNumber.replace(`${dialCode}`, isBD ? "0" : "")
    } else {
      actualNumber = candidateData?.phone_no
    }

    if (isBD && actualNumber && !validatePhoneNumber(actualNumber)) {
      alertContext.setOpenErrorAlert(true);
      alertContext.setResponseMessage("Please enter a valid phone number!");
      return;
    }

    if(fullNameRef.current?.value && gender && dobRef.current?.value && phoneNumber) {
      setIsFillup((prev) => ({...prev, personal: true}))
    }

    const paramData = {
      first_name: fullNameRef.current?.value ? fullNameRef.current?.value : null,
      dialCode: dialCode ? dialCode : candidateData?.dial_code,
      phone_no: actualNumber,
      gender: gender ? gender : null,
      dob: dobRef.current?.value,
      candidate_type_id: salaryInfos?.candidate_type_id,
      salary_type_id: salaryInfos?.salary_type_id,
      present_salary: salaryInfos?.present_salary,
      expected_salary: salaryInfos?.expected_salary,
    }

    setLoading(true);

    candidateRegisterFlow("personal_info", job_id, paramData)
    .then((response) => {
      if (response && response.success) {
        handleNext();
        setLoading(false);
      } else {
        alertContext.setOpenErrorAlert(true);
        alertContext.setResponseMessage(response.errors[0]?.message);
        setLoading(false);
      }
    })
    .finally(() => setLoading(false), alertContext.setResponseMessage(""));

    const ip = fetchIpAddress();
    const platform = "web";
    const stepNo = 1;

    stepperStepInfo(stepNo, ip, platform).then((response) => {
      if(response && response.success) {
        setLoading(false);
      }
    })
  };

  useEffect(() => {
    if(candidateData?.gender) {
      setGender(candidateData.gender)
    }
  }, [candidateData.gender])

  useEffect(() => {
    // get candidate job type
    getTypes().then((response) => {
      if (response) {
        setJobTypes(response.response.data);
      } else {
        console.log("error:", response);
      }
    });

    // get salary type
    getSalaryTypes().then((response) => {
      if (response) {
        setSalaryTypes(response.response.data);
      } else {
        console.log("error:", response);
      }
    });

  }, []);

  useEffect(() => {
    setSalaryInfos(candidateData?.candidate_salary_infos?.pop());
  },[candidateData.candidate_salary_infos]);

  
  // date field placeholder color change
  const [dob, setDob] = useState('');
  
  const dateHandleChange = (event) => {
    setDob(event.target.value);
  };

  const isPlaceholderFormat = (value) => {
    return value === '' || value === 'mm-dd-yyyy';
  };

  const handleSalaryInfo = (name) => (event) => {
    setSalaryInfos({ ...salaryInfos, [name]: event.target.value })
  }

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Typography align="left" variant="h6" sx={{ fontWeight: 600, fontSize: "16px", mb: 1 }}>
          Personal Info
        </Typography>
      </Box>

      <Box sx={{mx: -6}}>
        <Divider />
      </Box>

      {candLoader ? (
        <div className="mt-3 col-lg-12 col-md-12">
          <Skeleton variant="text" height={60} />
          <Skeleton variant="text" height={50} />
          <div className="text-left">
            <Skeleton variant="text" />
          </div>
          <div className="mt-3 text-left">
            <Skeleton variant="rounded" height={120} />
          </div>
          <div className="text-left">
            <Skeleton variant="text" width={80} height={50} />
          </div>
        </div>
      ) : (
        <Box sx={{marginX: {xs: -3, sm: 0}}}>
          <form
            className="ga_stepper_candidate_register_personal_info_form" // do not remove ga_ class
            onSubmit={(e) => [e.preventDefault(), handleSubmit()]}
          >
            <Grid container spacing={2} mt={3}>
              <Grid item md={6} xs={12}>
                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>
                  Full Name <RequiredAsterisk />
                </InputLabel>
                <TextField
                  required
                  type="text"
                  name="name"
                  variant="outlined"
                  placeholder="Enter Your Name"
                  fullWidth
                  inputRef={fullNameRef}
                  defaultValue={candidateData.first_name}
                  inputProps={{
                    style: {
                      padding: "10px 14px",
                    },
                  }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>
                    Phone Number <RequiredAsterisk />
                  </InputLabel>
                <PhoneInput
                    country={country}
                    value={phoneNumber || ''}
                    onChange={handlePhoneChange}
                    inputProps={{
                      name: 'mobile_number',
                      required: true,
                      // autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableSearch={true}
                    containerClass={classes.outlinedInput}
                    inputStyle={{
                      width: `calc(100% - 20px)`,
                      border: '1px solid #C4C4C4',
                      borderRadius: '4px',
                      fontSize: '16px',
                      fontWeight: 400,
                      color: 'rgba(0, 0, 0, 0.87)',
                      outline: 'none',
                      paddingTop: '20.5px',
                      paddingBottom: "20.5px",
                      marginLeft: "20px",
                  }}
                    searchStyle={{width: "90%"}}
                    dropdownStyle={{width: `${window.innerWidth > 560 ? "600%" : "400%"}`}}
                  />
              </Grid>

              <Grid item md={6} xs={12} sx={{position: "relative"}}>
                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>
                  Gender <RequiredAsterisk />
                </InputLabel>
                <Select
                  required
                  displayEmpty
                  name="gender"
                  fullWidth
                  value={gender}
                  onChange={(e)=> setGender(e.target.value)}
                  input={<OutlinedInput />}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    '& .MuiSelect-select': {
                        padding: "10px 14px"
                    }
                  }}
                >
                  <MenuItem disabled value="">
                  <span style={{color: '#949494'}}>Select Gender</span>
                  </MenuItem>
                  <MenuItem value={"male"} key={"male"}>
                    Male
                  </MenuItem>
                  <MenuItem value={"female"} key={"female"}>
                    Female
                  </MenuItem>
                  <MenuItem value={"others"} key={"others"}>
                    Others
                  </MenuItem>                 
                </Select>
                {gender && 
                    <IconButton size="small" onClick={() => setGender("")} sx={{position: "absolute", right: 25, bottom: 7}}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  }
              </Grid>

              <Grid item md={6} xs={12}>
                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>
                  Date of Birth <RequiredAsterisk />
                </InputLabel>
                <TextField
                  required
                  type="date"
                  name="dob"
                  variant="outlined"
                  fullWidth
                  inputRef={dobRef}
                  defaultValue={candidateData?.dob}
                  onChange={dateHandleChange}
                  InputProps={{
                    inputProps: {
                      min: "1950-01-01",
                      max: getDobMaxYear(),
                      style: {
                        padding: "10px 14px",
                        color: isPlaceholderFormat(dob) && (!candidateData.dob || candidateData.dob === '') ? '#949494' : 'black',
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item md={6} xs={12} sx={{position: "relative"}}>
                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Job Type</InputLabel>
                <Select
                  displayEmpty
                  name="candidate_type_id"
                  fullWidth
                  value={salaryInfos?.candidate_type_id || ""}
                  onChange={handleSalaryInfo("candidate_type_id")}
                  input={<OutlinedInput />}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    '& .MuiSelect-select': {
                        padding: "10px 14px"
                    }
                  }}
                >
                  <MenuItem disabled value="">
                    <span style={{color: '#949494'}}>Select Job Type</span>
                  </MenuItem>
                  {jobTypes?.length > 0
                    ? jobTypes?.map((type) => (
                        <MenuItem value={type.id} key={type.id}>
                          {type.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {(salaryInfos?.candidate_type_id) && 
                  <IconButton size="small" onClick={handleSalaryInfo("candidate_type_id")} sx={{position: "absolute", right: 25, bottom: 7}}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                }
              </Grid>

              <Grid item md={6} xs={12} sx={{position: "relative"}}>
                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Salary Type</InputLabel>
                <Select
                  displayEmpty
                  name="salary_type_id"
                  fullWidth
                  value={salaryInfos?.salary_type_id || ""}
                  onChange={handleSalaryInfo("salary_type_id")}
                  input={<OutlinedInput />}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    '& .MuiSelect-select': {
                        padding: "10px 14px"
                    }
                  }}
                >
                  <MenuItem disabled value="">
                  <span style={{color: '#949494'}}>Select Salary Type</span>
                  </MenuItem>
                  {salaryTypes?.length > 0
                    ? salaryTypes?.map((salary) => (
                        <MenuItem value={salary.id} key={salary.id}>
                          {salary.type}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {(salaryInfos?.salary_type_id) && 
                  <IconButton size="small" onClick={handleSalaryInfo("salary_type_id")} sx={{position: "absolute", right: 25, bottom: 7}}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                }
              </Grid>

              <Grid item md={6} xs={12}>
                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>
                  Current Salary <RequiredAsterisk />
                </InputLabel>
                <TextField
                  required
                  type="number"
                  name="present_salary"
                  variant="outlined"
                  placeholder="Enter Your Current Salary"
                  fullWidth
                  value={salaryInfos?.present_salary}
                  onChange={handleSalaryInfo("present_salary")}
                  inputProps={{
                    style: {
                      padding: "10px 14px",
                    },
                    onKeyDown: (e) => handleKeyDown(e),
                  }}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>
                  Expected Salary <RequiredAsterisk />
                </InputLabel>
                <TextField
                  required
                  type="number"
                  name="expected_salary"
                  variant="outlined"
                  placeholder="Enter Your Expected Salary"
                  fullWidth
                  value={salaryInfos?.expected_salary}
                  onChange={handleSalaryInfo("expected_salary")}
                  inputProps={{
                    style: {
                      padding: "10px 14px",
                    },
                    onKeyDown: (e) => handleKeyDown(e),
                  }}
                />
              </Grid>
            </Grid>

            <Box sx={{mx: {xs: -3, sm: -6}, paddingTop: "40px"}}>
              <Divider />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 4 }}>
              <Button variant="outlined" disabled sx={{ mr: 1, padding: {xs: "10px 16px"}, minWidth: {sm: "102px"}, color: "#0079C1", fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "16.5px", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", '&.Mui-disabled': {color: "#0079C1", borderColor: "#0079C1",} }}>
                <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.64706 1.10156L2 7.10156L7.64706 13.1016" fill="white"/>
                <path d="M7.64706 1.10156L2 7.10156M2 7.10156L7.64706 13.1016M2 7.10156L26 7.10156" stroke="#0079C1" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Back
              </Button>
              <Box sx={{ display: "flex" }}>
                <LoadingButton
                  sx={{ ml: {xs: 1, md: 2}, padding: {xs: "10px 16px"}, minWidth: {sm: "102px"}, color: "#FFFDFD", fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "20.5px", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize" }}
                  variant="contained"
                  loading={loading}
                  type="submit"
                >
                  Continue
                  <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.3529 13.1016L26 7.10156M26 7.10156L20.3529 1.10156M26 7.10156L2 7.10156" stroke="white" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </LoadingButton>
              </Box>
            </Box>  

          </form>
        </Box>
      )}
    </>
  );
}
