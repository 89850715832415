import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

function LinearProgressWithLabel(props) {
    return (
        <Stack spacing={1}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {/* Label */}
            <Typography variant="body1" sx={{display: {xs: "none", sm: "block"}, fontFamily: "Epilogue", fontWeight: 700, fontSize: "16px", color: "#72737C", lineHeight: "20px", marginRight: 2 }}>
                Profile Complete
            </Typography>
            
            {/* Progress Bar */}
            <Box sx={{ flexGrow: 1, marginRight: 1 }}>
                <LinearProgress 
                    value={props.value}
                    variant="determinate" 
                    sx={{
                    height: 12,
                    borderRadius: 5,
                    '& .MuiLinearProgress-bar': {
                    backgroundColor: '#0079C1' // Change the bar color
                    },
                    backgroundColor: '#E5F5FF' // Light blue background
                    }}
                />
            </Box>

            {/* Progress Percentage */}
            <Typography variant="body1" sx={{display: {xs: "none", sm: "block"}, fontFamily: "Epilogue", fontWeight: 700, fontSize: "16px", color: "#72737C", lineHeight: "20px", marginLeft: 1 }}>
                <span style={{ color: "#15A449" }}>{props.value}</span>/100%
            </Typography>
            </Box>

            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {/* Label */}
                <Typography variant="body1" sx={{display: {xs: "block", sm: "none"}, fontFamily: "Epilogue", fontWeight: 500, fontSize: "14px", color: "#72737C", lineHeight: "20px" }}>
                    Profile Complete
                </Typography>

                {/* Progress Percentage */}
                <Typography variant="body1" sx={{display: {xs: "block", sm: "none"}, fontFamily: "Epilogue", fontWeight: 500, fontSize: "14px", color: "#72737C", lineHeight: "20px"}}>
                    <span style={{ color: "#15A449" }}>{props.value}</span>/100%
                </Typography>
            </Box>
        </Stack>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function ProgressBar({profile_percent}) {
    return (
        <Box sx={{ width: '70%' }}>
            <LinearProgressWithLabel value={profile_percent} />
        </Box>
    );
}