import React, { useEffect, useState, useContext, useRef } from "react";
import "./AttachResume.css";
import { Link } from "react-router-dom";
import { CandidateContext } from "../../../context/CandidateContext";
import {
  getResumeListById,
  downloadResume,
  deleteResume,
} from "../../../../fetchAPI";
import Cookies from "js-cookie";
import Card from "react-bootstrap/Card";
import UpdateDeleteConfirmationModal from "../CandidateProfile/UpdateProfile/UpdateDeleteConfirmationModal";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useAlert } from "../../../context/AlertContext";
import ShortFooter from "../../ShortFooter";
import GlobalLoader from "../../GlobalLoader";

function AttachResume() {
  const { candidateData } = useContext(CandidateContext);
  const [resumes, setResumes] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadLoader, setUploadLoader] = useState(false);
  const [recallUseEffect, setRecallUseEffect] = useState(1);
  const [loader, setLoader] = useState(false);
  const [selectedResume, setSelectedResume] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const resumeUploadInput = useRef();
  const alertContext = useAlert();

  useEffect(() => {
    setLoader(true);
    getResumeListById(candidateData.id)
      .then((response) => {
        setResumes(response.response.data);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [candidateData, recallUseEffect]);

  /**
   * Get the file name from the link of the resume
   * @param {*} link
   * @returns filename
   */
  function fileName(link) {
    if (!link) {
      return null
    }
    let resumeReverse = link
      .split("")
      .reverse()
      .join("");
    let reverseArray = resumeReverse.split("/");
    let filename = reverseArray[0]
      .split("")
      .reverse()
      .join("");
    return filename;
  }

  /**
   * This funtion will download the resume
   * @param {int} resumeId
   * @param {string} resumeName
   */
  const resumeDownload = (resumeId, resumeName) => {
    downloadResume(resumeId)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${resumeName}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  /**
   * Get the selected file name for uploading
   * @param {file} event
   */
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  /**
   * This function will upload the resume
   * @param {file} e
   */
  //Upload Resume
  const bearerToken = Cookies.get("token");
  const handleSubmission = () => {
    setUploadLoader(true);

    const validTypes = [
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/pdf' // .pdf
    ];
    const maxSize = 10 * 1024 * 1024;

    // Check file type and size
    if (!validTypes.includes(selectedFile.type) && selectedFile.size > maxSize) {
      alertContext.setResponseMessage("Only DOC, DOCX, and PDF files are allowed And File size exceeds 10 MB!");
      setUploadLoader(false);
      alertContext.setOpenErrorAlert(true);
      return;
    }

    if (!validTypes.includes(selectedFile.type)) {
      alertContext.setResponseMessage("Only DOC, DOCX, and PDF files are allowed!");
      setUploadLoader(false);
      alertContext.setOpenErrorAlert(true);
      return;
    }

    if (selectedFile.size > maxSize) {
      alertContext.setResponseMessage("File size exceeds 10 MB!");
      setUploadLoader(false);
      alertContext.setOpenErrorAlert(true);
      return;
    }


    const formData = new FormData();
    formData.append("file", selectedFile);
    fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/resume/${candidateData.id}/upload`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
        body: formData,
      }
    )
      .then(async (res) => {
        let response = await res.json();
        if (response && response.success) {
          alertContext.setResponseMessage("Resume Uploaded Successfully!");
          setUploadLoader(false);
          setRecallUseEffect((prev) => prev + 1);
          setSelectedFile("");
          alertContext.setOpenAlert(true);
          resumeUploadInput.current.value = ""
        } else {
          alertContext.setResponseMessage(response.errors[0]['message'][0])
          alertContext.setOpenErrorAlert(true);
          setUploadLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  /**
   * Delete Resume
   * @param {int} candidateId
   * @param {int} resumeId
   */
  const handleDeleteClick = (candidateId, resumeId) => {
    deleteResume(candidateId, resumeId).then((response) => {
      setLoader(true);
      if (response) {
        alertContext.setResponseMessage(response.response.message);
        alertContext.setOpenAlert(true);
        setRecallUseEffect((prev) => prev + 1);
        setLoader(false);
        setOpenDeleteModal(false);
      } else {
        alertContext.setResponseMessage("Something went wrong!");
        alertContext.setOpenErrorAlert(true);
      }
    });
  };

  const dateFromTimeStamp = (timestamp) => {
    if (timestamp) {
      var time = new Date(timestamp);
      return time.toISOString().substring(0, 10);
    } else {
      return " ";
    }
  };

  const handleOpen = (itemId, index) => {
    setOpenDeleteModal(true);
    setSelectedResume(index);
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  return (
    <div style={{}}>
      <div className="" style={{ zIndex: 100 }}>
        <Card className="shadow-sm mt-4 mt-md-0">
          <Card.Header className="bg-white pt-4 px-3">
            <h4 className="upload-title">Upload Resume</h4>
            <p className="upload-text mb-3" style={{ fontSize: "16px" }}>
              Resume is the most important document recruiters look for.
              Recruiters generally do not look at profiles without resumes.
            </p>
          </Card.Header>
          <Card.Body>
            <div className="row px-3 px-md-4 py-3 py-md-5">
              <div className="col-lg-5 ">
                <div className="upload-box" style={{ background: "#FBFBFB" }}>
                  {uploadLoader ? (
                    // <div className="mx-auto">
                    //   <img
                    //     className=" img-fluid upload-loader"
                    //     src="/v2/icons/loading.gif"
                    //     alt=""
                    //   />
                    // </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "150px" }}
                    >
                      <CircularProgress />
                    </div>
                  ) : selectedFile != "" ? (
                    <>
                      <div
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => [setSelectedFile(""), resumeUploadInput.current.value = ""]}
                        className="pl-2 pt-2"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </div>
                      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "0", justifyContent: "center", alignItems: "center" }}>
                        <Typography
                          sx={{
                            width: { xs: "200px", sm: "400px", lg: "320px", xl: "450px" },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            '@media (min-width: 992px) and (max-width:1200px)': {
                              width: "200px"
                            },
                          }}
                        >
                          {selectedFile.name}
                        </Typography>
                        <br />
                        <Link
                          style={{
                            padding: '10px 8px',
                            fontSize: '18px',
                            fontFamily: 'Epilogue',
                            backgroundColor: 'rgb(0, 110, 255)',
                            color: 'white',
                            textTransform: 'uppercase',
                            borderRadius: '5px',
                            border: '1px solid rgb(0, 110, 255)',
                            width: '80%',
                            textAlign: "center"
                          }}
                          to="#"
                          onClick={handleSubmission}
                        >
                          Upload Resume
                        </Link>
                      </Box>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "150px" }}>
                      <div className="">
                        <label
                          className="d-flex justify-content-center align-items-center mx-auto w-75 py-2"
                          htmlFor="file-input"
                          style={{
                            background: "#EDF7FF",
                            borderRadius: "30px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            className=""
                            src="/v2/icons/upload-resume.svg"
                            alt=""
                            style={{ width: "18px" }}
                          />
                          <p
                            className="text-primay mb-0 pl-2  upload-text"
                            style={{
                              color: "#0079C1",
                              fontSize: "18px",
                              fontWeight: 700,
                            }}
                          >
                            Upload Resume
                          </p>
                        </label>{" "}
                        <p
                          className="text-center mb-0 mt-2 upload-text"
                          style={{ fontSize: "18px" }}
                        >
                          Supported Formats: doc, docx, pdf upto 10 MB
                        </p>
                      </div>
                    </div>
                  )}
                  <input ref={resumeUploadInput} id="file-input" onChange={changeHandler} type="file" />
                </div>
              </div>
              <div className="col-lg-1 middle-text text-center py-1 py-lg-0">
                -OR-
              </div>
              <div className="col-lg-5 ">
                <div
                  className="upload-box d-flex justify-content-center align-items-center"
                  style={{ background: "#FBFBFB" }}
                >
                  <Link
                    to="/generate-resume"
                    className="d-flex justify-content-center align-items-center w-75 py-1 cursor-pointer"
                    style={{
                      background: "#EDF7FF",
                      borderRadius: "28px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{ width: "30px" }}
                      src="/v2/icons/generate-cv.svg"
                      alt=""
                    />{" "}
                    <p
                      className="text-primay mb-0 pl-2 upload-text"
                      style={{
                        color: "#0079C1",
                        fontWeight: 700,
                        fontSize: "18px",
                      }}
                    >
                      Generate CV
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="mt-3 mt-md-4 mb-4 shadow-sm" style={{}}>
          <div className="bg-white border-bottom mx-3 py-4">
            <h4 className="upload-text bg-white mb-0">Uploaded Resume</h4>
          </div>
          <Card.Body className="px-3 py-0">
            {
              loader ? (
                <GlobalLoader />
              ) : resumes?.length > 0 ? (
                resumes
                  .filter((item) => item.resume_link !== null)
                  .map((resume, index) => (
                    <div
                      key={index}
                      className="resume-div d-md-flex justify-content-between align-items-center py-4"
                    >
                      <div className="responsive-div">
                        <a
                          href={resume.resume_full_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                          style={{
                            color: "#0079C1",
                            fontWeight: "bold",
                            fontSize: "18px",
                            marginBottom: "25px",
                          }}
                        >
                          {fileName(resume.resume_link)}
                        </a>

                        <p
                          className="upload-text mb-0"
                          style={{ fontSize: "18px" }}
                        >
                          Uploaded at - {dateFromTimeStamp(resume.created_at)}
                        </p>
                      </div>

                      <div className="d-flex justify-content-md-center align-items-center">
                        {resume.resume_category || ""}
                      </div>

                      <div className="d-flex justify-content-md-center align-items-center">
                        <img
                          style={{ width: "42px", cursor: "pointer" }}
                          src="/v2/icons/download.svg"
                          alt="Download"
                          onClick={() =>
                            resumeDownload(resume.id, fileName(resume.resume_link))
                          }
                        />
                        <img
                          className="pl-2"
                          style={{ width: "50px", cursor: "pointer" }}
                          src="/v2/icons/trash.svg"
                          alt="Delete"
                          onClick={() =>
                            handleOpen(candidateData.id, resume.id)
                          }
                        />
                      </div>
                    </div>
                  ))
              ) : (
                <div className="d-flex justify-content-center align-items-center py-5">
                  <div>
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        style={{ width: "80px" }}
                        src="/v2/icons/upload-fail.svg"
                        alt="No Resume"
                      />
                    </div>
                    <p
                      className="text-primary text-center mt-2 upload-text"
                      style={{
                        color: "#DB1616",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      You have not uploaded any resume
                    </p>
                  </div>
                </div>
              )
            }

          </Card.Body>
        </Card>
        <UpdateDeleteConfirmationModal
          open={openDeleteModal}
          onClose={handleClose}
          deleteItem={() => handleDeleteClick(candidateData.id, selectedResume)}
        />
      </div>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <ShortFooter />
      </Box>
    </div>
  );
}

export default AttachResume;
