import React, { useEffect, useState, useRef} from 'react';
import { TextField, Grid, InputLabel, Checkbox, Typography, Divider, Skeleton, Box, Button, Stack, FormControlLabel, FormGroup, Select, OutlinedInput, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { candidateRegisterFlow, stepperStepInfo } from '../../../../fetchAPI';
import { LoadingButton } from '@mui/lab';
import RequiredAsterisk from '../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk';
import { fetchIpAddress } from '../../../helpers/helperFunctions';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useAlert } from '../../../context/AlertContext';
import ExperienceInformationDeleteModal from './ExperienceDeleteModal';


export default function ExperienceInformation({ handleNext, handleBack, job_id, candidateData, candLoader, settingCandidateData, setIsFillup }) {
    const [experience, setExperience] = useState({
        organization_name: "",
        role: "",
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
        currently_working: false,
        is_fresher: false,
    })

    const organizationNameRef = useRef('')
    const roleRef = useRef('')
    const startDateRef = useRef('')
    const endDateRef = useRef('')
    const [loading, setLoading] = useState(false)
    const alertContext = useAlert();
    const [responseMessage, setResponseMessage] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        if (candidateData?.candidate_experience?.length) {
            const latestExperience = candidateData.candidate_experience[0];
            setExperience({...latestExperience, is_fresher: false});
        }
    }, [candidateData.candidate_experience]);

    useEffect(() => {
        if(candidateData.is_fresher) {
            setExperience({...experience, is_fresher: candidateData?.is_fresher === 1 ? true : false})
        }
    }, [candidateData.is_fresher])

    // OnChange handler
    const handleExperience = (name) => (event) => {
        if(name === "currently_working") {
            setExperience({ ...experience, [name]: event.target.checked })
        } else if(name === "start_date" || name === "end_date") {
            const date = new Date(event.$d)
            const formattedDate = dayjs(date).format("YYYY-MM-DD")
            setExperience({...experience, [name]: formattedDate})
        } else if (name === "is_fresher") {
            const exp = candidateData?.candidate_experience?.[0] || {};
            const hasExperienceData = exp.organization_name && exp.role && exp.start_date && (exp.end_date || exp.currently_working);
    
            if (hasExperienceData) {
                handleOpen();
                return;
            }
            setExperience({ ...experience, [name]: event.target.checked })
        } else {
            setExperience({ ...experience, [name]: event.target.value })
        }
    }

    const handleSUbmit = () => {
        setLoading(true)
        if((organizationNameRef.current?.value && roleRef.current?.value && startDateRef.current?.value && (endDateRef.current?.value || experience.currently_working)) || experience.is_fresher) {
            setIsFillup((prev) => ({...prev, experience: true}))
        }
        const paramData = {
            organization_name: experience.organization_name,
            role: experience.role,
            start_date: experience.start_date,
            end_date: experience.end_date,
            is_fresher: experience.is_fresher === true ? 1 : 0,
            currently_working: experience.currently_working,
        }

        if(!experience.organization_name) {
            alertContext.setResponseMessage("Company name is required");
            alertContext.setOpenErrorAlert(true);
            setLoading(false)
            return
        } if(!experience.role) {
            alertContext.setResponseMessage("Designation field is required");
            alertContext.setOpenErrorAlert(true);
            setLoading(false)
            return
        } if(!experience.start_date) {
            alertContext.setResponseMessage("Start date is required");
            alertContext.setOpenErrorAlert(true);
            setLoading(false)
            return
        } if(!experience.end_date && !experience.currently_working) {
            alertContext.setResponseMessage("End date is required");
            alertContext.setOpenErrorAlert(true);
            setLoading(false)
            return
        } if(experience.start_date && experience.end_date && experience.start_date > experience.end_date) {
            alertContext.setResponseMessage("End date can not be before start date");
            alertContext.setOpenErrorAlert(true);
            setLoading(false) 
            return
        }

        candidateRegisterFlow('work_experience', job_id, paramData)
            .then((response) => {
                if (response && response.success) {
                    handleNext()
                } else {
                    setResponseMessage(response.errors[0]?.message)
                }
            }).finally(() => setLoading(false), setResponseMessage(''))
        
        const ip = fetchIpAddress();
        const platform = "web";
        const stepNo = 3;

        stepperStepInfo(stepNo, ip, platform).then((response) => {
        if(response && response.success) {
            setLoading(false);
            }
        })

    }

    const handleFresher = () =>{
        setLoading(true)
        const paramData = {
            is_fresher: 1
        }
        candidateRegisterFlow('work_experience', job_id, paramData)
        .then((response) => {
            if (response && response.success) {
                handleNext()
            } else {
                setResponseMessage(response.errors[0]?.message)
            }
        }).finally(() => setLoading(false), setResponseMessage(''))
    }

    const handleOpen = () => {
        if(candidateData?.candidate_experience[0]) {
            setIsModalOpen(true)
        } else {
            setIsModalOpen(false)
        }
    }

    const handleClose = () => {
        setIsModalOpen(false)
        setExperience({ ...experience, is_fresher: false })
    }
    
    return (
        <>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Typography align="left" variant="h6" sx={{ fontWeight: 600, fontSize: "16px", mb: 1 }}>
                    Experience
                </Typography>
            </Box>

            <Box sx={{mx: -6}}>
                <Divider />
            </Box>
            {
                candLoader ?
                    <div className='mt-3 col-lg-12 col-md-12'>
                        <Skeleton variant="text" height={60} />
                        <Skeleton variant="text" height={50} />
                        <div className='text-left'>
                            <Skeleton variant="text" />
                        </div>
                        <div className='mt-3 text-left'>
                            <Skeleton variant="rounded" height={120} />
                        </div>
                        <div className='text-left'>
                            <Skeleton variant="text" width={80} height={50} />
                        </div>
                    </div>
                    :
                    <Box sx={{marginX: {xs: -3, sm: 0}}}>
                        <form
                            className="ga_stepper_candidate_register_work_qualification_form" // do not remove ga_ class
                            onSubmit={(e) => [e.preventDefault(), handleSUbmit()]}
                        >
                            {responseMessage ?
                                <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                    <center>
                                        <small style={{ color: 'red' }}> {responseMessage} </small>
                                    </center>
                                </div>
                                : ''
                            }
                            
                            {/* Fresher Button */}
                            <Grid container spacing={2} mt={3} mb={2}>
                                <Grid item xs={12}>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                size='medium' 
                                                onChange={handleExperience("is_fresher")}
                                                checked={experience.is_fresher}
                                                sx={{padding: "0px 9px 0px !important"}}
                                                // onClick={() => handleOpen()}
                                            />
                                        } 
                                        label="Fresher" 
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447",
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {
                                !experience.is_fresher &&
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Company Name <RequiredAsterisk /> </InputLabel>
                                            <TextField
                                                inputRef={organizationNameRef}
                                                required
                                                type='text'
                                                name='organization_name'
                                                variant='outlined'
                                                placeholder='Ex: atB Jobs Bangladesh'
                                                fullWidth
                                                value={experience.organization_name || ""}
                                                onChange={handleExperience("organization_name")}
                                                inputProps={{
                                                style: {
                                                    padding: "10px 14px",
                                                },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Designation <RequiredAsterisk /> </InputLabel>
                                            <TextField
                                                inputRef={roleRef}
                                                required
                                                type='text'
                                                name='role'
                                                variant='outlined'
                                                placeholder='Ex: UI/UX Designer'
                                                fullWidth
                                                value={experience.role || ""}
                                                onChange={handleExperience("role")}
                                                inputProps={{
                                                style: {
                                                    padding: "10px 14px",
                                                },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Start Date <RequiredAsterisk /> </InputLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                className='form-control'
                                                closeOnSelect={true}
                                                disableFuture={true}
                                                showToolbar={false}
                                                views={['year', 'month']}
                                                maxDate={new Date().toISOString()}
                                                value={experience.start_date}
                                                onChange={handleExperience('start_date')}
                                                renderInput={(params) => <TextField required {...params} placeholder='Select Start Date' InputProps={{ sx: {paddingRight: "0px", cursor: "pointer"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '11px'}, '& .MuiOutlinedInput-input::placeholder': { fontFamily: "Poppins", fontSize: "15px"}}} /> }
                                            />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box sx={{display: "flex", flexDirection: {xs: "column", sm: "row"}, justifyContent: "space-between"}}>
                                                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>End Date <RequiredAsterisk /> </InputLabel>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            size='medium' 
                                                            onChange={handleExperience("currently_working")}
                                                            checked={experience.currently_working}
                                                            sx={{padding: {xs: "0px 0px 6px 0px !important", sm: "0px 9px 2px !important"}}}
                                                        />
                                                    } 
                                                    label="Currently Working Here" 
                                                    componentsProps={{
                                                        typography: {
                                                            sx: {
                                                                fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447",
                                                            }
                                                        }
                                                    }}
                                                /> 
                                            </Box>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                className='form-control'
                                                closeOnSelect={true}
                                                disableFuture={true}
                                                showToolbar={false}
                                                views={['year', 'month']}
                                                maxDate={new Date().toISOString()}
                                                value={experience.end_date}
                                                disabled={experience.currently_working}
                                                onChange={handleExperience('end_date')}
                                                renderInput={(params) => <TextField required {...params} placeholder='Select End Date' InputProps={{ sx: {paddingRight: "0px", cursor: "pointer"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '11px'}, '& .MuiOutlinedInput-input::placeholder': { fontFamily: "Poppins", fontSize: "15px"}}} /> }
                                            />
                                            </LocalizationProvider>
                                        </Grid>

                                    </Grid>
                            }

                            <Box sx={{mx: {xs: -3, sm: -6}, paddingTop: "40px"}}>
                                <Divider />
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 4 }}>
                                <Button 
                                    variant="outlined" 
                                    // disabled 
                                    sx={{ 
                                        mr: 1, 
                                        padding: {xs: "10px 16px"}, 
                                        minWidth: {sm: "102px"}, 
                                        color: "#0079C1", 
                                        fontFamily: "Epilogue", 
                                        fontWeight: 600, 
                                        fontSize: "14px", 
                                        lineHeight: "16.5px", 
                                        display: "flex", 
                                        alignItems: "center", 
                                        gap: 1, 
                                        textTransform: "capitalize", 
                                        // '&.Mui-disabled': {color: "#0079C1", borderColor: "#0079C1",} 
                                        }}
                                        onClick={handleBack}
                                    >
                                        <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.64706 1.10156L2 7.10156L7.64706 13.1016" fill="white"/>
                                        <path d="M7.64706 1.10156L2 7.10156M2 7.10156L7.64706 13.1016M2 7.10156L26 7.10156" stroke="#0079C1" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        Back
                                </Button>
                                <Box sx={{ display: "flex" }}>
                                    {experience.is_fresher ? 
                                        <LoadingButton
                                            sx={{ ml: {xs: 1, md: 2}, padding: {xs: "10px 16px"}, minWidth: {sm: "102px"}, color: "#FFFDFD", fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "20.5px", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize" }}
                                            variant="contained"
                                            loading={loading}
                                            onClick={handleFresher}
                                        >
                                            Continue
                                            <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.3529 13.1016L26 7.10156M26 7.10156L20.3529 1.10156M26 7.10156L2 7.10156" stroke="white" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </LoadingButton>
                                        :
                                        <LoadingButton
                                            sx={{ ml: {xs: 1, md: 2}, padding: {xs: "10px 16px"}, minWidth: {sm: "102px"}, color: "#FFFDFD", fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "20.5px", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize" }}
                                            variant="contained"
                                            loading={loading}
                                            type="submit"
                                        >
                                            Continue
                                            <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.3529 13.1016L26 7.10156M26 7.10156L20.3529 1.10156M26 7.10156L2 7.10156" stroke="white" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </LoadingButton>
                                    }
                                </Box>
                            </Box>  
                        </form>

                        <ExperienceInformationDeleteModal
                            loading={loading}
                            open={isModalOpen}
                            onClose={handleClose}
                            deleteItem={() => handleFresher()}
                        />
                    </Box>
            }
        </>
    )
}