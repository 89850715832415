import React, { useEffect, useState } from 'react'
import { updateCandidateBasicInfo } from '../../../../../fetchAPI'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import EditSVG from './component/EditSVG'
import SinglePersonalInfoBlock from './component/SinglePersonalInfoBlock'
import { useAlert } from '../../../../context/AlertContext'
import { RELIGION_LIST } from '../../../../../constants'
import { useLocation } from 'react-router-dom'
import { Alert, Box, CircularProgress, Grid, InputLabel, TextField, Typography } from '@mui/material'
import RequiredAsterisk from './component/RequiredAsterisk'

function UpdatePersonalInfo(props) {
    const alertContext = useAlert();
    const genderList = [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }, { label: 'Other', value: 'Other' }]
    const [updatePersonal, setUpdatePersonal] = useState({})

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { search } = useLocation();

    let errorsObj = { dob: "", present_address: "" };
    const [updateErrors, setUpdateErrors] = useState({})

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const enableUpdate = (item) => {
        setUpdatePersonal({
            father_name: item.father_name,
            mother_name: item.mother_name,
            dob: item.dob,
            gender: item.gender,
            martial_status: item.martial_status,
            nationality: item.nationality,
            religion: item.religion,
            present_address: item.present_address,
            permanent_address: item.permanent_address,
        })
    }

    const handleChange = (name) => (event) => {
        setUpdatePersonal({ ...updatePersonal, [name]: event.target.value })
    }

    const updateCandidatePersonal = (candId, updateParameters) => {
        setLoading(true);

        let error = false;
        const errorObj = { ...errorsObj };

        if (!updateParameters.dob) {
            errorObj.dob = "Date of Birth is required";
            error = true;
        }
        if (!updateParameters.present_address) {
            errorObj.present_address = "Present Address is required";
            error = true;
        }

        setUpdateErrors(errorObj);

        if(error) {
            setLoading(false)
        } else {
            setModalOpen(false);
            updateCandidateBasicInfo(candId, updateParameters)
                .then(response => {
                    if (response && response.success) {
                        alertContext.setResponseMessage(response.response.message)
                        alertContext.setOpenAlert(true)
                        props.renderState()
                        setLoading(false)
                    } else {
                        const message = response.errors?.map(msg => {
                            alertContext.setResponseMessage(msg.message)
                        })
                        alertContext.setOpenErrorAlert(true)
                        setLoading(false)
                    }
                })
        }


    }

    const todayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = yyyy + '-' + mm + '-' + dd;

        return formattedToday
    }

    return (
        <Box id='personal_box'>
            <Box className='card shadow-sm'>
                <Box className={customStyles.cardHeader}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#424447"}}>Personal Info</Typography>
                        <button className={`btn ${customStyles.editButton}`} onClick={() => {enableUpdate(props.candidateInfo); setModalOpen(true)}}>
                            <div style={{ marginRight: '10px' }}>Edit</div>
                            <EditSVG />
                        </button>
                    </Box>
                </Box>
                <Box className='card-body'>
                    {loading ? 
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                            <CircularProgress />
                        </Box>
                        :
                        <>
                            <SinglePersonalInfoBlock title="Father's Name" value={props.candidateInfo.father_name} />
                            <SinglePersonalInfoBlock title="Mother's Name" value={props.candidateInfo.mother_name} />
                            <SinglePersonalInfoBlock title="Date of Birth" value={props.candidateInfo.dob} />
                            <SinglePersonalInfoBlock title="Gender" value={props.candidateInfo.gender} />
                            <SinglePersonalInfoBlock title="Martial Status" value={props.candidateInfo.martial_status} />
                            <SinglePersonalInfoBlock title="Nationality" value={props.candidateInfo.nationality} />
                            <SinglePersonalInfoBlock title="Religion" value={props.candidateInfo.religion} />
                            <SinglePersonalInfoBlock title="Present Address" value={props.candidateInfo.present_address} />
                            <SinglePersonalInfoBlock title="Permanent Address" value={props.candidateInfo.permanent_address} />
                        </>
                    }
                </Box>
            </Box>

            <CandidateUpdateModal open={modalOpen} onClose={() => {setModalOpen(false); setUpdatePersonal({}); setUpdateErrors({})}} title='Personal Information' onFormSubmit={() => updateCandidatePersonal(props.candidateInfo.id, updatePersonal)}>
                <Grid container spacing={3} rowSpacing={1} sx={{padding: {md: 3}}}>
                    <Grid item xs={12} md={6} lg={4}>
                        <InputLabel>Father's Name</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={updatePersonal.father_name} onChange={handleChange('father_name')} placeholder='Enter Name' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <InputLabel>Mother's Name</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={updatePersonal.mother_name} onChange={handleChange('mother_name')} placeholder='Enter Name' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <InputLabel>Date of Birth <RequiredAsterisk /></InputLabel>
                        <TextField required className='form-control' fullWidth defaultValue={updatePersonal.dob} onChange={handleChange('dob')} placeholder='Enter' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='date' inputProps={{ max: `${new Date().toISOString().split('T')[0]}` }} />
                        {updateErrors.dob && <Alert severity="error">{updateErrors.dob}</Alert>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className='form-group'>
                        <InputLabel>Gender</InputLabel>
                        <select onChange={handleChange('gender')} className='form-control' style={{borderRadius: '5px'}}>
                            <option value=''>Select Gender</option>
                            {
                                genderList.map((item) => {
                                    return (<option key={item.value} selected={item.value == updatePersonal.gender ? 'selected' : ''} value={item.value}>{item.value}</option>)
                                })
                            }
                        </select>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className='form-group'>
                        <InputLabel>Marital Status</InputLabel>
                        <select onChange={handleChange('martial_status')} className='form-control' style={{borderRadius: '5px'}}>
                            <option value=''>Select Status</option>
                            <option selected={updatePersonal.martial_status == 'Married' ? 'selected' : ''} value='Married'>Married</option>
                            <option selected={updatePersonal.martial_status == 'Single' ? 'selected' : ''} value='Single'>Single</option>
                        </select>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <InputLabel>Nationality</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={updatePersonal.nationality} onChange={handleChange('nationality')} placeholder='Enter' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className='form-group'>
                        <InputLabel>Religion</InputLabel>
                        <select onChange={handleChange('religion')} className='form-control' style={{borderRadius: '5px'}}>
                            <option value=''>Select Religion</option>
                            {
                                RELIGION_LIST.map((item) => {
                                    return (<option key={item.id} selected={item.value == updatePersonal.religion ? 'selected' : ''} value={item.value}>{item.label}</option>)
                                })
                            }
                        </select>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <InputLabel>Present Address <RequiredAsterisk /> </InputLabel>
                        <TextField required className='form-control' fullWidth defaultValue={updatePersonal.present_address} onChange={handleChange('present_address')} placeholder='Enter Address' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                        {updateErrors.present_address && <Alert severity="error">{updateErrors.present_address}</Alert>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <InputLabel>Permanent Address</InputLabel>
                        <TextField className='form-control' fullWidth defaultValue={updatePersonal.permanent_address} onChange={handleChange('permanent_address')} placeholder='Enter Address' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                    </Grid>
                </Grid>
            </CandidateUpdateModal>
        </Box >
    )
}

export default UpdatePersonalInfo