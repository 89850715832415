
import { makeStyles } from "@material-ui/core";
import { Box, Grid, Paper, Typography, Divider, List, ListItem, ListItemText, Skeleton, Stack } from "@mui/material";
import React, { useContext } from "react";
import { CandidateContext } from "../../../context/CandidateContext";
import parse from 'react-html-parser'
import TemplateSkeleton from "./TemplateSkeleton";

function CVTemplate({ baseColor, candidateData }) {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing({ xs: 0, md: 4 }),
        },
        paper: {
            padding: theme.spacing(6),
            color: theme.palette.text.primary,
            backgroundColor: '#ffffff',
            marginBottom: theme.spacing(5)
        },
        header: {
            marginBottom: theme.spacing(3),
        },
        headerTitle: {
            fontWeight: 700,
            fontFamily: 'Epilogue'
        },
        headerText: {
            fontSize: '18px',
            fontFamily: 'Epilogue'
        },
        section: {
            marginBottom: theme.spacing(3),
        },
        sectionTitle: {
            marginBottom: theme.spacing(1),
            fontWeight: 'bold',
            fontFamily: 'Poppins',
            color: baseColor,
        },
        experienceTitle: {
            fontWeight: 500,
            fontFamily: 'Poppins',
        },
        experienceDescription: {
            margin: theme.spacing(2),
            fontWeight: 400,
            fontFamily: 'Poppins',
        },
        listPrimaryText: {
            fontFamily: 'Poppins',
            color: theme.palette.text.primary,
        },
        listSecondaryText: {
            fontFamily: 'Poppins',
            color: theme.palette.text.secondary,
        },
        divider: {
            margin: theme.spacing(2, 0),
        },
    }));

    const classes = useStyles();

    const convertDateFormat = (initialDateFormat) => {
        const dateStr = initialDateFormat;
        const date = new Date(dateStr);

        const options = { year: 'numeric', month: 'short' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        return formattedDate
    }

    return (
        <Box className={classes.root} sx={{ width: '100%', height: 'auto' }}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10}>
                    <Paper className={classes.paper}>
                        <Box className={classes.header}>

                            {/* Candidate Name */}
                            <Typography
                                className={classes.headerTitle}
                                variant="h5"
                                gutterBottom
                            >
                                {candidateData?.first_name}
                            </Typography>

                            {/* Candidate Profession */}
                            <Typography
                                className={classes.headerText}
                                variant="body1"
                                gutterBottom
                            >
                                {candidateData?.profession}
                            </Typography>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.section}>
                            {/* Candidate Contact Info */}
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontFamily: 'Poppins, sans-serif' }}
                            >
                                Number: +{candidateData?.dial_code} {candidateData?.phone_no} · Email: {candidateData?.user?.email}
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontFamily: 'Poppins, sans-serif' }}
                            >
                                Address: {candidateData?.present_address}
                            </Typography>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.section}>
                            <Typography variant="h6" className={classes.sectionTitle}>
                                Profile Summary
                            </Typography>
                            <Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif', fontSize: '15px' }}>
                                {parse(candidateData?.intro)}
                            </Typography>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.section}>
                            <Typography variant="h6" className={classes.sectionTitle}>
                                Key Competencies
                            </Typography>
                            <Grid container spacing={0}>
                                {
                                    candidateData?.skills.map((skill, index) => {
                                        return (
                                            <Grid key={index} item xs={12} sm={4} p={0}>
                                                <Typography variant='subtitle1'>{skill.skill_name}</Typography>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.section}>
                            <Typography variant="h6" className={classes.sectionTitle}>
                                Professional Experience
                            </Typography>


                            {
                                candidateData?.candidate_experience.map((experience, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Box>
                                                <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                                                    <Stack spacing={0}>
                                                        <Typography variant="subtitle1" className={classes.experienceTitle}>
                                                            {experience?.role}
                                                        </Typography>
                                                        <Typography variant="subtitle1" className={classes.experienceTitle}>
                                                            {experience.organization_name}
                                                        </Typography>
                                                    </Stack>
                                                    <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
                                                        {convertDateFormat(experience.start_date)} - {experience.currently_working == 1 ? 'Present' : convertDateFormat(experience.end_date)}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="body1" className={classes.experienceDescription} paragraph>
                                                    {parse(experience.organization_desc)}
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Box>

                        <Divider className={classes.divider} />

                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Box className={classes.section}>
                                    <Typography variant="h6" className={classes.sectionTitle}>
                                        Education
                                    </Typography>
                                    <List dense>
                                        {
                                            candidateData?.candidate_education.map((education, index) => {
                                                return (
                                                    <ListItem key={index}>
                                                        <ListItemText
                                                            primary={education.degree_name}
                                                            secondary={education.major ? `Majors: ${education.major}, ${education.degree_institute}` : education.degree_institute}
                                                            classes={{ primary: classes.listPrimaryText, secondary: classes.listSecondaryText }}
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box className={classes.section}>
                                    <Typography variant="h6" className={classes.sectionTitle}>
                                        Certifications
                                    </Typography>
                                    <List dense>
                                        {
                                            candidateData?.certification.map((certificate, index) => {
                                                return (
                                                    <ListItem key={index}>
                                                        <ListItemText
                                                            primary={certificate.name}
                                                            secondary={certificate.institute}
                                                            classes={{ primary: classes.listPrimaryText, secondary: classes.listSecondaryText }}
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

export default function ATSTemplate({ baseColor }) {
    const { candidateData } = useContext(CandidateContext)
    return candidateData?.id ? <CVTemplate baseColor={baseColor} candidateData={candidateData} /> : <TemplateSkeleton />

}