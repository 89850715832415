import React, { useEffect, useState, useRef, useContext } from 'react'
import { postCandidateExperienceByID, deleteCandidateExperienceByID, updateCandidateExperienceByID } from '../../../../../fetchAPI';
import Alert from '@mui/material/Alert';
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal';
import Select from 'react-select'
import JoditEditor from 'jodit-react';
import AddButton from './component/AddButton';
import customStyles from './CandidateUpdateProfile.module.css'
import CandidateUpdateModal from './component/CandidateUpdateModal';
import SingleExpBlock from './component/SingleExpBlock';
import EditSVG from './component/EditSVG';
import DeleteSVG from './component/DeleteSVG';
import { useAlert } from '../../../../context/AlertContext';
import SkillAddForm from './component/SkillAddForm';
import { DropdownContext } from '../../../../context/DropdownContext';
import { WORK_TYPE_LIST } from '../../../../../constants';
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import RequiredAsterisk from './component/RequiredAsterisk';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Custom breakpoint at 768px
const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        md: 768,  
      },
    },
  });

function UpdateEmployment(props) {
    const { types, departments, industries } = useContext(DropdownContext);

    const [experienceData, setExperienceData] = useState({ 
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
        currently_working: 0 
    })
    const [updateExperienceData, setUpdateExperienceData] = useState({})

    const [loader, setLoader] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [filteredSkillOptions, setFilteredSkillOptions] = useState([]);

    const alertContext = useAlert();

    const skillAddFormContainer = useRef();
    const skillAddFormContainerinUpdateExpForm = useRef();
    const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const enableUpdate = (item) => {
        setUpdateExperienceData({
            ...updateExperienceData,
            id: item.id,
            organization_name: item.organization_name,
            role: item.role,
            start_date: item.start_date,
            end_date: item.end_date,
            organization_desc: item.organization_desc,
            currently_working: item.currently_working,
            technology: item.technology?.map(val => val.id).toString(),
            candidate_type_id: item.candidate_type?.id,
            candidate_type_name: item.candidate_type?.name,
            industry_id: item.industry?.id,
            industry_name: item.industry?.name,
            department_id: item.department?.id,
            department_name: item.department?.name,
            company_location: item.company_location,
            candidate_work_type: item.candidate_work_type,
        })

    }

    const handleInputChange = (inputValue) => {
        if (inputValue) {
            // Filter skills based on input and limit to 10 results
            const filtered = props.skills
                .filter((item) =>
                    item.skill_name.toLowerCase().startsWith(inputValue.toLowerCase())
                )
                .slice(0, 10);
            setFilteredSkillOptions(filtered);
            setMenuIsOpen(true);
        } else {
            setFilteredSkillOptions([]); 
            setMenuIsOpen(false);
        }
    };

    const handleUpdateChange = (name) => (event) => {
        switch (name) {
            case "start_date":
            case "end_date": {
                const date = new Date(event.$d);
                const formattedDate = dayjs(date).format("YYYY-MM-DD");
                setUpdateExperienceData({
                    ...updateExperienceData,
                    [name]: formattedDate
                });
                break;
            }
            case "candidate_type_id":
                setUpdateExperienceData({
                    ...updateExperienceData,
                    [name]: event.value,
                    "candidate_type_name": event.label
                });
                break;
            case "industry_id":
                setUpdateExperienceData({
                    ...updateExperienceData,
                    [name]: event.value,
                    "industry_name": event.label
                });
                break;
            case "department_id":
                setUpdateExperienceData({
                    ...updateExperienceData,
                    [name]: event.value,
                    "department_name": event.label
                });
                break;
            case "candidate_work_type":
                setUpdateExperienceData({
                    ...updateExperienceData,
                    [name]: event.value
                });
                break;
            default:
                setUpdateExperienceData({
                    ...updateExperienceData,
                    [name]: event.target.value
                });
                break;
        }
    };    

    const handleChange = (name) => (event) => {
        if(name === "start_date" || name === "end_date"){
            const date = new Date(event.$d)
            const formattedDate = dayjs(date).format("YYYY-MM-DD")
            setExperienceData({...experienceData, [name]: formattedDate})
        } else {
            setExperienceData({ ...experienceData, [name]: event.target.value })
        }
    }

    const handleCheckBox = (name) => (event) => {
        setExperienceData({ ...experienceData, [name]: +(event.target.checked) })
    }

    const handleUpdateCheckBox = (name) => (event) => {
        setUpdateExperienceData({ ...updateExperienceData, [name]: +(event.target.checked) })
    }

    const handleChangeSelect = (name) => (event) => {
        switch (name) {
            case "candidate_type_id":
                setExperienceData({
                    ...experienceData,
                    [name]: event.value,
                    "candidate_type_name": event.label 
                });
                break;
            case "industry_id":
                setExperienceData({
                    ...experienceData,
                    [name]: event.value,
                    "industry_name": event.label 
                });
                break;
            case "department_id":
                setExperienceData({
                    ...experienceData,
                    [name]: event.value,
                    "department_name": event.label 
                });
                break;
            case "candidate_work_type":
                setExperienceData({
                    ...experienceData,
                    [name]: event.value,
                });
                break;
            default:
                return;
        }
    };    

    const handleUpdateChangeSelect = (name) => (event) => {
        switch (name) {
            case "industry_id":
                setUpdateExperienceData({
                    ...updateExperienceData,
                    [name]: event.value,
                    "industry_name": event.label 
                });
                break;
            case "department_id":
                setUpdateExperienceData({
                    ...updateExperienceData,
                    [name]: event.value,
                    "department_name": event.label 
                });
                break;
            default:
                return;
        }
    }

    let errorsObj = { role: '', organization_name: '', start_date: '', end_date: '', desc: '', dateValid: '', currently_working: '' };
    const [errors, setErrors] = useState('')

    const insertCandidateExperience = (candId, body) => {
        let error = false;
        const errorObj = { ...errorsObj };

        if (!body.role) {
            errorObj.role = 'Designation is Required';
            error = true;
        }
        if (!body.organization_name) {
            errorObj.organization_name = 'Organization Name is Required';
            error = true;
        }
        if (!body.start_date) {
            errorObj.start_date = 'Start Date is Required';
            error = true;
        }
        if (body.currently_working === 0 && !body.end_date) {
            errorObj.end_date = 'End Date is Required';
            error = true;
        }
        if (body.end_date < body.start_date) {
            errorObj.dateValid = 'End Date cannot be before Start Date';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setAddModalOpen(false);
            setExperienceData({});
            postCandidateExperienceByID(candId, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    setSkillMultiValue([])
                    setSkillUpdateMultiVaue([])
                    props.renderState()
                    setLoader(false);
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    const [skillMultiValue, setSkillMultiValue] = useState([])
    const [skillUpdateMultiValue, setSkillUpdateMultiVaue] = useState([])

    useEffect(() => {
        setUpdateExperienceData({ ...updateExperienceData, 'technology': skillUpdateMultiValue?.map(val => val.value).toString() })
    }, [skillUpdateMultiValue])

    useEffect(() => {
        setExperienceData({ ...experienceData, 'technology': skillMultiValue?.map(val => val.value).toString() })
    }, [skillMultiValue])

    const [updateErrors, setUpdateErrors] = useState('')

    const updateCandidateExperience = (candId, body) => {
        let error = false;
        const errorObj = { ...errorsObj };

        if (!body.role) {
            errorObj.role = 'Designation is Required';
            error = true;
        }
        if (!body.organization_name) {
            errorObj.organization_name = 'Organization Name is Required';
            error = true;
        }
        if (!body.start_date) {
            errorObj.start_date = 'Start Date is Required';
            error = true;
        }
        if (body.currently_working === 0 && body.end_date === '') {
            errorObj.end_date = 'End Date is Required';
            error = true;
        }
        if (body.end_date && body.end_date < body.start_date) {
            errorObj.dateValid = 'End Date cannot be before Start Date';
            error = true;
        }
        setUpdateErrors(errorObj);
        if (error) {
            // Do not call the Post API here
            setLoader(false);
        }
        else {
            setUpdateModalOpen(false);
            setUpdateExperienceData({});
            updateCandidateExperienceByID(candId, body.id, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    setSkillMultiValue([])
                    setSkillUpdateMultiVaue([])
                    setLoader(false);
                    props.renderState()
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    const [deleteItemId, setDeleteItemId] = useState('')

    const deleteCandidateExperience = (candId, experienceId, index) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateExperienceByID(candId, experienceId).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)

                props.experiences.splice(index, 1);
                props.renderState()
                setLoader(false)
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)

                props.renderState()
            }
        })
    }

    const handleTechnology = (name) => (event) => {
        setSkillMultiValue(event)
    }

    const handleUpdateTechnology = (name) => (event) => {
        setSkillUpdateMultiVaue(event)
    }

    // const [addSkillInfo, setAddSkillInfo] = useState('')
    const [addSkillModal, setAddSkillModal] = useState(false)
    const [addSkillModalUpdateExpForm, setAddSkillModalUpdateExpForm] = useState(false)

    return (
        <Box id='employment_box'>
            <Box className='card shadow-sm'>
                <Box className={customStyles.cardHeader}>
                    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#424447"}}>Work Experience</Typography>
                        <Box
                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#0275D8", fontFamily: "Epilogue", fontWeight: "600 !important", fontSize: "16px !important", backgroundColor: "#E5F5FF", padding: "6px 16px !important", cursor: "pointer" }}
                            onClick={() => setAddModalOpen(true)}
                        >
                            <Typography sx={{color: "#0275D8", marginRight: "10px", fontFamily: "Epilogue", fontSize: "16px", fontWeight: 600}}>Add</Typography>
                            <Box sx={{display: "flex", alignItems: "center"}}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="10" fill="#CAEBFF"/>
                                <path d="M6.99362 9.80457C6.27657 9.80457 5.56145 9.80457 4.8444 9.80457C4.40448 9.80457 4.12153 10.0836 4.13316 10.4848C4.13603 10.6259 4.18635 10.7619 4.27601 10.8709C4.36567 10.9799 4.48944 11.0556 4.62735 11.0856C4.72962 11.1044 4.83344 11.1135 4.93742 11.1127H9.07696C9.38122 11.1127 9.34052 11.072 9.34246 11.386C9.34246 12.7852 9.34246 14.1844 9.34246 15.5836C9.34246 16.041 9.61378 16.3298 10.0188 16.3201C10.1598 16.3184 10.2961 16.2693 10.4058 16.1807C10.5155 16.0921 10.5921 15.9692 10.6235 15.8317C10.6438 15.7296 10.6535 15.6257 10.6525 15.5216C10.6525 14.1224 10.6525 12.7212 10.6525 11.322C10.6525 11.1495 10.6952 11.105 10.8677 11.1069C12.2669 11.1069 13.6661 11.1069 15.0673 11.1069C15.1647 11.1089 15.2621 11.1011 15.358 11.0836C15.5021 11.0525 15.6313 10.9729 15.7239 10.8582C15.8166 10.7434 15.8671 10.6003 15.8671 10.4528C15.8671 10.3053 15.8166 10.1623 15.7239 10.0475C15.6313 9.93272 15.5021 9.85314 15.358 9.82201C15.2558 9.80289 15.1518 9.79445 15.0479 9.79682H10.9277C10.6099 9.79682 10.6545 9.84139 10.6545 9.51388C10.6545 8.14178 10.6545 6.7684 10.6545 5.39372C10.6564 5.29629 10.6487 5.1989 10.6312 5.10302C10.6019 4.9568 10.5228 4.82525 10.4075 4.73073C10.2921 4.63621 10.1476 4.58456 9.99847 4.58456C9.84934 4.58456 9.70481 4.63621 9.58945 4.73073C9.4741 4.82525 9.39504 4.9568 9.36572 5.10302C9.34988 5.19908 9.34275 5.29638 9.3444 5.39372C9.3444 6.79294 9.3444 8.19411 9.3444 9.59333C9.3444 9.75612 9.30177 9.8007 9.13897 9.79876C8.4258 9.80457 7.70874 9.80457 6.99362 9.80457Z" fill="#0079C1"/>
                                </svg>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.experiences?.map((item, index) => {
                                return (
                                    <Box key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <Box className={customStyles.singleExp} >
                                            <Typography sx={{fontSize: "18px", fontWeight: 700, color: "#0079c1"}}>{item.role}</Typography>
                                            <Box className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => {
                                                    enableUpdate(item);
                                                    setSkillUpdateMultiVaue(item.technology?.map(item => {
                                                        return (
                                                            { label: item.skill_name, value: item.skill_id }
                                                        )
                                                    }));
                                                    setUpdateModalOpen(true);
                                                }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </Box>
                                        </Box>
                                        <SingleExpBlock item={item} />
                                    </Box>
                                )
                            })
                    }
                </Box>
            </Box>
            <CandidateUpdateModal form="candidateExpAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setExperienceData({})]} title='New Experience'>
                <ThemeProvider theme={theme}>
                    <Box className='card-body'>
                        <form id='candidateExpAddForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateExperience(props.candId, experienceData); }}>
                            <Grid container spacing={3} rowSpacing={1}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Designation <RequiredAsterisk/></InputLabel>
                                    <TextField className='form-control' fullWidth onChange={handleChange('role')} placeholder='Type your Designation' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                                    {errors.role && <Alert severity="error">{errors.role}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <InputLabel>Employment type</InputLabel>
                                        <Select
                                            options={types?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('candidate_type_id')}
                                            placeholder='Select Type'
                                            styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  padding: '6px !important',
                                                }),
                                                menu: (base) => ({
                                                  ...base,
                                                }),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Company Name <RequiredAsterisk/></InputLabel>
                                    <TextField className='form-control' fullWidth onChange={handleChange('organization_name')} placeholder='Type your Organization Name' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                                    {errors.organization_name && <Alert severity="error">{errors.organization_name}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <InputLabel>Industry</InputLabel>
                                        <Select
                                            options={industries?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('industry_id')}
                                            placeholder='Add Industry'
                                            styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  padding: '6px !important',
                                                }),
                                                menu: (base) => ({
                                                  ...base,
                                                }),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <InputLabel>Department </InputLabel>
                                        <Select
                                            options={departments?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('department_id')}
                                            placeholder='Add Department'
                                            styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  padding: '6px !important',
                                                }),
                                                menu: (base) => ({
                                                  ...base,
                                                }),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <InputLabel>Start Date <RequiredAsterisk/></InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className='form-control'
                                        closeOnSelect={true}
                                        disableFuture={true}
                                        showToolbar={false}
                                        views={['year', 'month']}
                                        maxDate={new Date().toISOString()}
                                        value={experienceData.start_date}
                                        onChange={handleChange('start_date')}
                                        renderInput={(params) => <TextField {...params} InputProps={{ sx: {paddingRight: "0px"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}, '& .MuiOutlinedInput-input::placeholder': { fontSize: '13.5px'}}} /> }
                                    />
                                    </LocalizationProvider>
                                    {errors.start_date && <Alert severity="error">{errors.start_date}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <InputLabel>End Date {!experienceData.currently_working ? <RequiredAsterisk/> : ''} </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className='form-control'
                                        closeOnSelect={true}
                                        disableFuture={true}
                                        showToolbar={false}
                                        views={['year', 'month']}
                                        maxDate={new Date().toISOString()}
                                        value={experienceData.end_date}
                                        disabled={experienceData.currently_working == 1 ? true : false}
                                        onChange={handleChange('end_date')}
                                        renderInput={(params) => <TextField {...params} InputProps={{ sx: {paddingRight: "0px"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}, '& .MuiOutlinedInput-input::placeholder': { fontSize: '13.5px'}}} /> }
                                    />
                                    </LocalizationProvider>
                                    <FormControlLabel control={<Checkbox onChange={handleCheckBox('currently_working')} />} label="Currently working here"/>                                    
                                    {errors.end_date && <Alert severity="error">{errors.end_date}</Alert>}
                                    {!experienceData?.currently_working && errors.dateValid && <Alert severity="error">{errors.dateValid}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Company Location</InputLabel>
                                    <TextField className='form-control' fullWidth onChange={handleChange('company_location')} placeholder='Type location' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                                </Grid>
                                <Grid item xs={12} md={6}>                                    
                                    <div className="form-group">
                                        <InputLabel>Candidate Work Type</InputLabel>
                                        <Select
                                            options={WORK_TYPE_LIST?.map((item) => {
                                                return (
                                                    { label: item.label, value: item.value }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('candidate_work_type')}
                                            placeholder='Select Work Type'
                                            styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  padding: '6px !important',
                                                }),
                                                menu: (base) => ({
                                                  ...base,
                                                }),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='form-group'>
                                        <InputLabel>Job Responsibilities </InputLabel>
                                        <JoditEditor
                                            value={experienceData.organization_desc || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setExperienceData({ ...experienceData, organization_desc: content })}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={10}>
                                    <InputLabel>Area of Expertise / Skills</InputLabel>
                                </Grid>
                                <Grid item xs={10} sx={{zIndex: 20}}>
                                    <Select
                                        placeholder='Select Skill'
                                        options={filteredSkillOptions.map((item) => ({
                                            label: item.skill_name,
                                            value: item.id,
                                        }))}
                                        onInputChange={handleInputChange}
                                        menuIsOpen={menuIsOpen}
                                        value={skillMultiValue}
                                        // isDisabled={true}
                                        isMulti
                                        onChange={handleTechnology('technology')}
                                        className='skill-update-container m-t10'
                                        classNamePrefix='skill-update'
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderRadius: '5px !important',

                                            }),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <img className='plus-skill mt-3' src='/v2/icons/plus.png'
                                        onClick={() => [setAddSkillModal((prev) => !prev), setTimeout(() => skillAddFormContainer.current?.scrollIntoView({ behavior: "smooth" }), 200)]}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <div ref={skillAddFormContainer}>
                            <SkillAddForm
                                open={addSkillModal}
                                candId={props.candId}
                                skillMultiValue={skillMultiValue}
                                setSkillMultiValue={setSkillMultiValue}
                                renderState={props.renderState}
                            />
                        </div>
                    </Box>
                </ThemeProvider>
            </CandidateUpdateModal>

            <CandidateUpdateModal form="candidateExpUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateExperienceData({})]} title='Update Experience'>
                <ThemeProvider theme={theme}>
                    <Box className='card-body'>
                        <form id='candidateExpUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateCandidateExperience(props.candId, updateExperienceData); }}>
                            <Grid container spacing={3} rowSpacing={1}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Designation <RequiredAsterisk/></InputLabel>
                                    <TextField className='form-control' fullWidth onChange={handleUpdateChange('role')} value={updateExperienceData.role} placeholder='Type your Designation' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                                    {updateErrors.role && <Alert severity="error">{updateErrors.role}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <InputLabel>Employment type</InputLabel>
                                        <Select
                                        value={updateExperienceData.candidate_type_id ? {label: updateExperienceData.candidate_type_name, value: updateExperienceData.candidate_type_id} : ""}
                                            options={types?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleUpdateChange('candidate_type_id')}
                                            placeholder='Select Type'
                                            styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  padding: '6px !important',
                                                }),
                                                menu: (base) => ({
                                                  ...base,
                                                }),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Company Name <RequiredAsterisk/></InputLabel>
                                    <TextField className='form-control' fullWidth onChange={handleUpdateChange('organization_name')} value={updateExperienceData.organization_name} placeholder='Type your Organization Name' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                                    {updateErrors.organization_name && <Alert seveSrity="error">{updateErrors.organization_name}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <InputLabel>Industry</InputLabel>
                                        <Select
                                            value={updateExperienceData.industry_id ? { label: updateExperienceData.industry_name, value: updateExperienceData.industry_id } : null}
                                            options={industries?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleUpdateChangeSelect('industry_id')}
                                            placeholder='Add Industry'
                                            styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  padding: '6px !important',
                                                }),
                                                menu: (base) => ({
                                                  ...base,
                                                }),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <InputLabel>Department </InputLabel>
                                        <Select
                                            value={updateExperienceData.department_id ? { label: updateExperienceData.department_name, value: updateExperienceData.department_id } : null}
                                            options={departments?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleUpdateChangeSelect('department_id')}
                                            placeholder='Add Department'
                                            styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  padding: '6px !important',
                                                }),
                                                menu: (base) => ({
                                                  ...base,
                                                }),
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <InputLabel>Start Date <RequiredAsterisk/></InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className='form-control'
                                        closeOnSelect={true}
                                        disableFuture={true}
                                        showToolbar={false}
                                        views={['year', 'month']}
                                        maxDate={new Date().toISOString()}
                                        value={updateExperienceData.start_date}
                                        onChange={handleUpdateChange('start_date')}
                                        renderInput={(params) => <TextField {...params} InputProps={{ sx: {paddingRight: "0px"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}, '& .MuiOutlinedInput-input::placeholder': { fontSize: '13.5px'}}} /> }
                                    />
                                    </LocalizationProvider>
                                    {updateErrors.start_date && <Alert severity="error">{updateErrors.start_date}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <InputLabel>End Date {!updateExperienceData.currently_working ? <RequiredAsterisk/> : ''} </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        className='form-control'
                                        closeOnSelect={true}
                                        disableFuture={true}
                                        showToolbar={false}
                                        views={['year', 'month']}
                                        maxDate={new Date().toISOString()}
                                        value={updateExperienceData.end_date}
                                        disabled={updateExperienceData.currently_working == 1 ? true : false}
                                        onChange={handleUpdateChange('end_date')}
                                        renderInput={(params) => <TextField {...params} InputProps={{ sx: {paddingRight: "0px"}, endAdornment: <InputAdornment sx={{position: "absolute", right: 12}}><CalendarMonthOutlinedIcon /></InputAdornment>}} sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}, '& .MuiOutlinedInput-input::placeholder': { fontSize: '13.5px'}}} /> }
                                    />
                                    </LocalizationProvider>
                                    <FormControlLabel control={<Checkbox checked={updateExperienceData?.currently_working} onChange={handleUpdateCheckBox('currently_working')} />} label="Currently working here"/>                                    
                                    {updateErrors.end_date && <Alert severity="error">{updateErrors.end_date}</Alert>}
                                    {!updateExperienceData.currently_working && updateErrors.dateValid && <Alert severity="error">{updateErrors.dateValid}</Alert>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>Company Location</InputLabel>
                                    <TextField className='form-control' fullWidth onChange={handleUpdateChange('company_location')} value={updateExperienceData.company_location} placeholder='Type location' sx={{'& .MuiOutlinedInput-input': { padding: '13.5px'}}} type='text' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <InputLabel>Candidate Work Type</InputLabel>
                                        <Select
                                        value={updateExperienceData.candidate_work_type ? {label: updateExperienceData.candidate_work_type, value: updateExperienceData.candidate_work_type} : ""}
                                            options={WORK_TYPE_LIST?.map((item) => {
                                                return (
                                                    { label: item.label, value: item.value }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleUpdateChange('candidate_work_type')}
                                            placeholder='Select Work Type'
                                            styles={{
                                                control: (base) => ({
                                                  ...base,
                                                  padding: '6px !important',
                                                }),
                                                menu: (base) => ({
                                                  ...base,
                                                }),
                                            }}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel>Job Responsibilities </InputLabel>
                                    <JoditEditor
                                        value={updateExperienceData.organization_desc || ''}
                                        buttons={[
                                            'bold', '|',
                                            'italic', '|',
                                            'ul', '|', 'underline', '|']}
                                        className='m-t10'
                                        onChange={(content) => setUpdateExperienceData({ ...updateExperienceData, organization_desc: content })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <label>Area of Expertise / Skills</label>
                                </Grid>
                                <Grid item xs={11} sx={{zIndex: 20}}>
                                    <Select
                                        placeholder='Select Skill'
                                        value={skillUpdateMultiValue}
                                        options={filteredSkillOptions.map((item) => ({
                                            label: item.skill_name,
                                            value: item.id,
                                        }))}
                                        onInputChange={handleInputChange}
                                        menuIsOpen={menuIsOpen}
                                        isMulti
                                        onChange={handleUpdateTechnology('technology')}
                                        className='skill-update-container m-t10'
                                        classNamePrefix='skill-update'
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderRadius: '5px !important',
                                            }),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <img className='plus-skill mt-3' src='/v2/icons/plus.png'
                                        onClick={() => [setAddSkillModalUpdateExpForm((prev) => !prev), setTimeout(() => skillAddFormContainerinUpdateExpForm.current?.scrollIntoView({ behavior: "smooth" }), 200)]}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <div ref={skillAddFormContainerinUpdateExpForm}>
                            <SkillAddForm
                                open={addSkillModalUpdateExpForm}
                                candId={props.candId}
                                skillUpdateMultiValue={skillUpdateMultiValue}
                                setSkillUpdateMultiVaue={setSkillUpdateMultiVaue}
                                renderState={props.renderState}
                            />
                        </div>
                    </Box>
                </ThemeProvider>
            </CandidateUpdateModal>

            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteCandidateExperience(props.candId, deleteItemId)} />
        </Box>
    )

}

export default UpdateEmployment