import React, { useRef, useState, useEffect } from 'react';
import { TextField, Grid, InputLabel, Box, Divider, Button, Typography, Select, MenuItem, OutlinedInput, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {Skeleton} from '@mui/material';
import { candidateRegisterFlow, stepperStepInfo, getQualifications } from '../../../../fetchAPI';
import RequiredAsterisk from '../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk';
import { fetchIpAddress } from '../../../helpers/helperFunctions';
import ClearIcon from '@mui/icons-material/Clear'; 

function generateYearArray(startYear = 1900) {
    const currentYear = new Date().getFullYear();
    const years = [];
    
    for (let year = currentYear; year >= startYear; year--) {
      years.push({ label: year.toString(), value: year });
    }
    
    return years;
  }

  export default function EducationInformation({handleNext, handleBack, job_id, candidateData, candLoader, settingCandidateData, setIsFillup}) {
    const institutenameRef = useRef('')
    const degreeNameRef = useRef('')
    const degreeFieldRef = useRef('')
    const gradeRef = useRef('')
    const endDateRef = useRef('')
    const currentlyStudyingRef = useRef('')
    const get_degree_year = generateYearArray();
    const [get_degree_name, setGetDegreeName] = useState([])
    
    const [loading, setLoading]= useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [latestEduInfo, setLatestEduInfo] = useState({})

    useEffect(()=>{
        if(candidateData.candidate_education){
            setLatestEduInfo(candidateData.candidate_education?.length !== 0 ? candidateData.candidate_education[0] : {})
        }
    },[candidateData])

    // get qualifications
     useEffect(() => {
        getQualifications().then((response) => {
            if(response && response.success) {
                setGetDegreeName(response.response.data)
            }
        })
     }, [])

    // OnChange handler
    const handleEducation = (name) => (event) => {
        if(name === "is_studying") {
            setLatestEduInfo({ ...latestEduInfo, [name]: event.target.checked })
        } else {
            setLatestEduInfo({ ...latestEduInfo, [name]: event.target.value })
        }
    }

    const handleSUbmit =()=>{
        setLoading(true)
        if(institutenameRef.current?.value && degreeNameRef.current?.value && gradeRef.current?.value && (endDateRef.current?.value || currentlyStudyingRef.current?.checked)) {
            setIsFillup((prev) => ({...prev, education: true}))
        }
        const paramData = {
            degree_institute : institutenameRef.current?.value,
            degree_id : degreeNameRef.current?.value,
            major: degreeFieldRef.current?.value,
            degree_grade : gradeRef.current?.value,
            degree_end : endDateRef.current?.value,
            is_studying : currentlyStudyingRef.current?.checked,
        }
        candidateRegisterFlow('academic_qualification', job_id, paramData )
        .then((response)=> {
            if(response && response.success){
                handleNext()
            }else{
                setResponseMessage(response.errors[0]?.message)
            }
        }).finally(()=> setLoading(false), setResponseMessage(''))

        const ip = fetchIpAddress();
        const platform = "web";
        const stepNo = 2;
    
        stepperStepInfo(stepNo, ip, platform).then((response) => {
          if(response && response.success) {
            setLoading(false);
          }
        })

    }


    return (
        <>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Typography align="left" variant="h6" sx={{ fontWeight: 600, fontSize: "16px", mb: 1 }}>
                    Education
                </Typography>
            </Box>

            <Box sx={{mx: -6}}>
                <Divider />
            </Box>
            {
                candLoader?
                <div className='mt-3 col-lg-12 col-md-12'>
                    <Skeleton variant="text" height={60} />
                    <Skeleton variant="text" height={50} />
                    <div className='text-left'>
                        <Skeleton variant="text" />
                    </div>
                    <div className='mt-3 text-left'>
                        <Skeleton variant="rounded" height={120} />
                    </div>
                    <div className='text-left'>
                        <Skeleton variant="text" width={80} height={50} />
                    </div>
                </div>
                :
                <Box sx={{marginX: {xs: -3, sm: 0}}}>
                    <form 
                    className="ga_stepper_candidate_register_academic_qualification_form" // do not remove ga_ class
                    onSubmit={(e) => [e.preventDefault(), handleSUbmit()]}
                    >
                        {responseMessage ?
                            <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                {
                                    responseMessage?.map((item,index)=>{
                                        return(
                                            <center key={index}>
                                                <small style={{ color: 'red' }}> {item} </small>
                                            </center> 
                                        )
                                    })
                                }
                            </div>
                            : ''}
                        <Grid container spacing={2} mt={3}>
                            <Grid item md={6} xs={12}>
                                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Institute Name <RequiredAsterisk /> </InputLabel>
                                <TextField
                                    inputRef={institutenameRef}
                                    required
                                    type='text'
                                    name='degree_institute'
                                    variant='outlined'
                                    placeholder='Ex: North South University'
                                    fullWidth
                                    onChange={handleEducation("degree_institute")}
                                    value={latestEduInfo?.degree_institute || ""}
                                    inputProps={{
                                    style: {
                                        padding: "10px 14px",
                                    },
                                    }}
                                />
                            </Grid>

                            <Grid item md={6} xs={12} sx={{position: "relative"}}>
                                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Degree Name <RequiredAsterisk /> </InputLabel>
                                <Select
                                    inputRef={degreeNameRef}
                                    required
                                    displayEmpty
                                    name="degree_id"
                                    fullWidth
                                    value={latestEduInfo.degree_id || ""}
                                    onChange={handleEducation("degree_id")}
                                    input={<OutlinedInput />}
                                    inputProps={{ "aria-label": "Without label" }}
                                    sx={{
                                        '& .MuiSelect-select': {
                                            padding: "10px 14px"
                                        }
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span style={{color: '#949494'}}>Ex: Bachelor’s</span>
                                    </MenuItem>
                                    {get_degree_name?.length > 0
                                        ? get_degree_name?.map((item) => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))
                                        : 
                                        null
                                    }
                                </Select>

                                {(latestEduInfo.degree_id) && 
                                    <IconButton size="small" onClick={handleEducation("degree_id")} sx={{position: "absolute", right: 25, bottom: 7}}>
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                }
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Field of Study</InputLabel>
                                <TextField
                                    inputRef={degreeFieldRef}
                                    type='text'
                                    name='major'
                                    variant='outlined'
                                    placeholder='Ex: Business'
                                    fullWidth
                                    onChange={handleEducation("major")}
                                    value={latestEduInfo?.major || ""}
                                    inputProps={{
                                    style: {
                                        padding: "10px 14px",
                                    },
                                    }}
                                />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Grade / CGPA {latestEduInfo?.is_studying ? "" : <RequiredAsterisk />} </InputLabel>
                                <TextField
                                    inputRef={gradeRef}
                                    required={latestEduInfo?.is_studying ? false : true}
                                    type='text'
                                    name='degree_grade'
                                    variant='outlined'
                                    placeholder='Example: A+ or 3.50'
                                    fullWidth
                                    value={latestEduInfo?.degree_grade || ""}
                                    onChange={handleEducation("degree_grade")}
                                    inputProps={{
                                    style: {
                                        padding: "10px 14px",
                                    },
                                    }}
                                />
                            </Grid>

                            <Grid item md={6} xs={12} sx={{position: "relative"}}>
                                <InputLabel sx={{fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447", paddingBottom: "4px" }}>Passing Year {latestEduInfo?.is_studying ? "" : <RequiredAsterisk />} </InputLabel>
                                <Select
                                    inputRef={endDateRef}
                                    required={latestEduInfo?.is_studying ? false : true}
                                    disabled={latestEduInfo.is_studying}
                                    displayEmpty
                                    name="degree_end"
                                    fullWidth
                                    value={latestEduInfo.degree_end || ""}
                                    onChange={handleEducation("degree_end")}
                                    input={<OutlinedInput />}
                                    inputProps={{ "aria-label": "Without label" }}
                                    sx={{
                                        '& .MuiSelect-select': {
                                            padding: "10px 14px"
                                        }
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span style={{color: '#949494'}}>Ex: 2022</span>
                                    </MenuItem>
                                    {get_degree_year?.length > 0
                                        ? get_degree_year?.map((item) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))
                                        : 
                                        null
                                    }
                                </Select>

                                {(latestEduInfo?.degree_end) && 
                                    <IconButton size="small" onClick={handleEducation("degree_end")} sx={{position: "absolute", right: 25, bottom: 7}}>
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                }
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Box sx={{marginTop: {md: 2}}}>
                                    <FormControlLabel 
                                        inputRef={currentlyStudyingRef} 
                                        control={
                                            <Checkbox 
                                                size='medium' 
                                                onChange={handleEducation("is_studying")} 
                                                checked={latestEduInfo.is_studying || false}
                                                disabled={!!latestEduInfo?.degree_end} 
                                            />
                                        } 
                                        label="Currently Studying" 
                                        componentsProps={{
                                            typography: {
                                                sx: {
                                                    fontFamily: "Poppins", fontWeight: 500, fontSize: "14px", lineHeight: "19.5px", color: "#424447",
                                                }
                                            }
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{mx: {xs: -3, sm: -6}, paddingTop: "40px"}}>
                            <Divider />
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 4 }}>
                            <Button 
                                variant="outlined" 
                                // disabled 
                                sx={{ 
                                    mr: 1, 
                                    padding: {xs: "10px 16px"}, 
                                    minWidth: {sm: "102px"}, 
                                    color: "#0079C1", 
                                    fontFamily: "Epilogue", 
                                    fontWeight: 600, 
                                    fontSize: "14px", 
                                    lineHeight: "16.5px", 
                                    display: "flex", 
                                    alignItems: "center", 
                                    gap: 1, 
                                    textTransform: "capitalize", 
                                    // '&.Mui-disabled': {color: "#0079C1", borderColor: "#0079C1",} 
                                    }}
                                    onClick={handleBack}
                                >
                                    <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.64706 1.10156L2 7.10156L7.64706 13.1016" fill="white"/>
                                    <path d="M7.64706 1.10156L2 7.10156M2 7.10156L7.64706 13.1016M2 7.10156L26 7.10156" stroke="#0079C1" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Back
                            </Button>
                            <Box sx={{ display: "flex" }}>
                                <LoadingButton
                                    sx={{ ml: {xs: 1, md: 2}, padding: {xs: "10px 16px"}, minWidth: {sm: "102px"}, color: "#FFFDFD", fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "20.5px", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize" }}
                                    variant="contained"
                                    loading={loading}
                                    type="submit"
                                >
                                    Continue
                                    <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.3529 13.1016L26 7.10156M26 7.10156L20.3529 1.10156M26 7.10156L2 7.10156" stroke="white" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </LoadingButton>
                            </Box>
                        </Box>  
                    </form>
                </Box>
            }
        </>
    )
}
