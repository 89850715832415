import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'

function ExperienceInformationDeleteModal(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <center>
              <img src='/images/stop.png' style={{ height: '80px', width: '80px', marginBottom: '50px' }} />
            </center>
            You have already added work experience, do you want to delete it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button
            variant='outlined'
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={props.loading} 
            variant='contained'
            color='error'
            onClick={() => props.deleteItem()}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ExperienceInformationDeleteModal