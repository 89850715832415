import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { Box, Typography, Card, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

function PlusIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 12.75H12.75V16.5C12.75 16.9125 12.4148 17.25 12 17.25C11.5852 17.25 11.25 16.9125 11.25 16.5V12.75H7.5C7.08525 12.75 6.75 12.4125 6.75 12C6.75 11.5875 7.08525 11.25 7.5 11.25H11.25V7.5C11.25 7.0875 11.5852 6.75 12 6.75C12.4148 6.75 12.75 7.0875 12.75 7.5V11.25H16.5C16.9147 11.25 17.25 11.5875 17.25 12C17.25 12.4125 16.9147 12.75 16.5 12.75ZM12 0C5.37225 0 0 5.37 0 12C0 18.63 5.37225 24 12 24C18.6278 24 24 18.63 24 12C24 5.37 18.6278 0 12 0Z" fill="#5AB8EB"/>
        </svg>
    )
}

function MinusIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#5AB8EB"/>
        <rect x="5" y="11" width="13" height="2" rx="1" fill="white"/>
        </svg>
    )
}

const ProfileStrengthData = [
    {
        id: 1,
        title: "Basic Details",
        value: 30,
        subTitle: [
        {
            id: 1,
            title: "Name",
            condition: (candidateData) => candidateData?.user?.name,
            path: "basic_info_block",
            value: 6,
        },
        {
            id: 2,
            title: "Phone Number",
            condition: (candidateData) => candidateData?.dial_code && candidateData?.phone_no,
            path: "basic_info_block",
            value: 6,
        },
        {
            id: 3,
            title: "Available From",
            condition: (candidateData) => candidateData?.available_date,
            path: "basic_info_block",
            value: 6,
        },
        {
            id: 4,
            title: "Present Salary",
            condition: (candidateData) => candidateData?.is_fresher || candidateData?.candidate_types?.[0]?.pivot?.present_salary,
            path: "basic_info_block",
            value: 6,
        },
        {
            id: 5,
            title: "Expected Salary",
            condition: (candidateData) => candidateData?.candidate_types?.[0]?.pivot?.expected_salary,
            path: "basic_info_block",
            value: 6,
        },
        ]
    },
    {
        id: 2,
        title: "Profile Summary",
        value: 6,
        subTitle: [
        {
            id: 1,
            title: "Profile Summary",
            condition: (candidateData) => candidateData?.intro,
            path: "about_box",
            value: 6,
        },
        ],
    },
    {
        id: 9,
        title: "Personal Info",
        value: 18,
        subTitle: [
        {
            id: 1,
            title: "Date of Birth",
            condition: (candidateData) => candidateData?.dob,
            path: "personal_box",
            value: 6,
        },
        {
            id: 2,
            title: "Gender",
            condition: (candidateData) => candidateData?.gender,
            path: "personal_box",
            value: 6,
        },
        {
            id: 3,
            title: "Present Address",
            condition: (candidateData) => candidateData?.present_address,
            path: "personal_box",
            value: 6,
        },
        ]
    },
    {
        id: 3,
        title: "Education",
        value: 6,
        subTitle: [
        {
            id: 1,
            title: "Education",
            condition: (candidateData) => candidateData?.candidate_education?.length,
            path: "education_box",
            value: 6,
        },
        ],
    },
    {
        id: 4,
        title: "Skills",
        value: 6,
        subTitle: [
        {
            id: 1,
            title: "Skills",
            condition: (candidateData) => candidateData?.skills?.length,
            path: "skill_box",
            value: 6,
        },
        ],
    },
    {
        id: 5,
        title: "Experience",
        value: 6,
        subTitle: [
        {
            id: 1,
            title: "Experience",
            condition: (candidateData) => candidateData?.is_fresher || candidateData?.candidate_experience?.length,
            path: "employment_box",
            value: 6,
        },
        ],
    },
    {
        id: 6,
        title: "Project",
        value: 6,
        subTitle: [
        {
            id: 1,
            title: "Project",
            condition: (candidateData) => candidateData?.projects?.length,
            path: "project_box",
            value: 6,
        },
        ],
    },
    {
        id: 7,
        title: "Certificate",
        value: 6,
        subTitle: [
        {
            id: 1,
            title: "Certificate",
            condition: (candidateData) => candidateData?.certification?.length,
            path: "certificate_box",
            value: 6, 
        },
        ],
    },
    {
        id: 8,
        title: "Resume",
        value: 10,
        subTitle: [
        {
            id: 1,
            title: "Resume",
            condition: (candidateData) => candidateData?.candidate_resume?.length,
            path: "resume_box",
            value: 10, 
        },
        ],
    },
    {
        id: 10,
        title: "Career & Application",
        value: 6,
        subTitle: [
        {
            id: 1,
            title: "Looking for job status",
            condition: (candidateData) => candidateData?.searching_for_job_status,
            path: "update_candidate_career_box",
            value: 6, 
        },
        ],
    },
];

export default function ProfileStrengthView({candidateData, profileStrength}) {
    const [expanded, setExpanded] = useState(0);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <Card
            className="shadow-sm steps"
            sx={{
            padding: '10px',
            border: '1px solid #DADADA',
            marginTop: "-25px",
            }}
        > 
            <Box textAlign="center" sx={{mb: 2, py: 1}}>
                <Typography
                    sx={{
                    fontFamily: 'Epilogue',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#424447',
                    mb: 1
                    }}
                >
                    Your Profile Strength 
                    <Typography component="span" sx={{ color: '#15A449', px: 0.5 }}>
                        ({profileStrength}%)
                    </Typography>
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Epilogue',
                        fontSize: '13px',
                        fontWeight: 500,
                        color: '#72737C',
                        pt: 1,
                    }}
                >
                    Complete the following steps to finish setting up your profile
                </Typography>
            </Box>
            <hr />

            {/* profile strength details */}
            {ProfileStrengthData.map((item) => (
                <Accordion
                    key={item.id}
                    expanded={expanded === item.id}
                    onChange={handleAccordionChange(item.id)}
                    sx={{
                        boxShadow: "unset",
                        my: 3,
                        borderRadius: "6px",
                        marginTop: "0px !important",
                        marginBottom: "12px",
                        '&::before': {
                            backgroundColor: "#FFFFFF",
                        },
                    }}
                    elevation={0}
                >
                    <AccordionSummary sx={{ backgroundColor: "#E5F5FF", '&.MuiAccordionSummary-root': {minHeight: "auto", py: 1}, '& .MuiAccordionSummary-content': {margin: "0 !important"}}} expandIcon={expanded === item.id ? <MinusIcon /> : <PlusIcon />} >
                        <Typography sx={{color: "#424447", fontFamily: "Epilogue", fontWeight: 700, fontSize: "14px"}} >
                            {item.title} (+{item.value}%)
                        </Typography>
                    </AccordionSummary>
                    {item.subTitle?.length !== 0 && item.subTitle.map((item) => {
                        const condition = item.condition(candidateData);
                        
                        return (
                            <AccordionDetails sx={{padding: "0px 0px !important"}} key={item.id}>
                                <Box sx={{display: "flex", alignItems: "center", columnGap: 1, py: "10px"}}> 
                                    <img src={condition ? '/v2/icons/new-check.png' : '/v2/icons/new-unfinished.png'} alt="" /> 
                                    <Link to={`/candidate-update-profile?scrollTo=${item.path}`}>
                                        <Typography component="span" sx={{ color: condition ? "#15A449" : "#0079C1", fontFamily: "Epilogue", fontSize: "14px", fontWeight: 600 }}>
                                            {item.title} ({condition ? "" : "+"}{item.value}%)
                                        </Typography>
                                    </Link>
                                </Box>
                            </AccordionDetails>
                        )
                      }
                    )}
                </Accordion>
            ))}
        </Card>
    )
}