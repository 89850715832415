import React, { useContext, useState } from "react";
import { insertCandidateSkillByID } from "../../../../../../fetchAPI";
import { useAlert } from "../../../../../context/AlertContext";
import Select from 'react-select'
import { DropdownContext } from "../../../../../context/DropdownContext";


export default function SkillAddForm({ open, candId, skillMultiValue, skillUpdateMultiValue, setSkillUpdateMultiVaue, setSkillMultiValue, renderState }) {

    const [addSkillInfo, setAddSkillInfo] = useState({})
    const alertContext = useAlert();

    const { skills } = useContext(DropdownContext)
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [filteredSkillOptions, setFilteredSkillOptions] = useState([]);

    const handleChangeSelect = (name) => (event) => {
        setAddSkillInfo({ ...addSkillInfo, [name]: event.value, skill_label: event.label })
    }

    const handleInputChange = (inputValue) => {
        if (inputValue) {
            // Filter skills based on input and limit to 10 results
            const filtered = skills
                .filter((item) =>
                    item.skill_name.toLowerCase().startsWith(inputValue.toLowerCase())
                )
                .slice(0, 10);
            setFilteredSkillOptions(filtered);
            setMenuIsOpen(true);
        } else {
            setFilteredSkillOptions([]); 
            setMenuIsOpen(false);
        }
    };

    const insertingCandidateSkill = (candId, body) => {
        if(body.year_of_experience) {
            const experience = parseInt(body.year_of_experience)
            if(experience > 100) {
                alertContext.setOpenErrorAlert('Value must be less than or equal to 100')
                alertContext.setResponseMessage('Value must be less than or equal to 100')
                return
            }
        }

        // Update Candidate Skill By ID API 
        insertCandidateSkillByID(candId, body).then((response) => {
            if (response.success) {
                alertContext.setOpenAlert(true)
                alertContext.setResponseMessage(response.response.message)
                if (skillMultiValue) {
                    setSkillMultiValue([...skillMultiValue, { value: body.skill_id, label: body.skill_label }])
                }
                if (skillUpdateMultiValue) {
                    setSkillUpdateMultiVaue([...skillUpdateMultiValue, { value: body.skill_id, label: body.skill_label }])
                }
                renderState()
                setAddSkillInfo({});
            }
            else {
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
            }
        })
    }

    return (
        <div style={{ display: `${open ? 'block' : 'none'}`, position: 'relative', zIndex: '2' }} className="mt-3">
            <form onSubmit={(e) => [e.preventDefault(), insertingCandidateSkill(candId, addSkillInfo)]} id="skillAddFormComponent">
                <div className='row' style={{alignItems:'end', marginBottom: '20px !important'}}>
                    <div className='col-md-5 col-12 mb-3' style={{ zIndex: '2', marginBottom: '20px' }}>
                        <label>Select Skill</label>
                        <Select
                            placeholder='Select Skill'
                            options={filteredSkillOptions.map((item) => ({
                                label: item.skill_name,
                                value: item.id,
                            }))}
                            onInputChange={handleInputChange}
                            menuIsOpen={menuIsOpen}
                            onChange={handleChangeSelect('skill_id')}
                            className='skill-update-container'
                            classNamePrefix='skill-update'
                            styles={{ control: (provided, state) => ({
                                ...provided,
                                borderRadius: '5px !important',
                                height: '44px'
                            }), }}
                            value={addSkillInfo.skill_label ? { label: addSkillInfo.skill_label, value: addSkillInfo.skill_id } : {}}
                        />
                    </div>
                    <div className='col-md-5 col-12 mb-3'>
                        <label>Number of Years</label>
                        <input type='number' className='form-control year-box'
                            onChange={(e) => setAddSkillInfo({ ...addSkillInfo, year_of_experience: e.target.value })}
                            // onBlur={() => insertingCandidateSkill(props.candId, insertSkill)}
                            placeholder='Number of Years'
                            style={{ width: '100%' }}
                            value={addSkillInfo.year_of_experience || ''}
                        />
                    </div>
                    <div className='col-md-2 col-12 mb-3'>
                        <button type="submit" form="skillAddFormComponent" className="btn btn-primary">Add</button>
                    </div>
                </div>

            </form>
        </div>
    )
}