import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Divider,
  Box,
  Typography,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  candidateRegisterFlow,
  getResumeListById,
  getJobCxoOrNot,
  postCxoEligibility,
  stepperStepInfo,
  deleteResume
} from "../../../../fetchAPI";
import NoResumeUploaded from "./NoResumeUploaded";
import ApplyCxoModal from "./ApplyCxoModal";
import { fetchIpAddress } from "../../../helpers/helperFunctions";
import { useAlert } from "../../../context/AlertContext";
import Cookies from "js-cookie";
import UpdateDeleteConfirmationModal from "../../CandidateComponents/CandidateProfile/UpdateProfile/UpdateDeleteConfirmationModal";

function CloudUploadIcon() {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21.2847" cy="21.8175" r="20.8692" fill="#E5F5FF"/>
    <path d="M25.1596 15.0121L21.4032 11.4766M21.4032 11.4766L17.6467 15.0121M21.4032 11.4766L21.4032 26.5024" stroke="#0079C1" stroke-width="2.08692" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.9456 21.4915V29.4218C10.9456 30.5744 11.8799 31.5088 13.0325 31.5088H28.8931C30.0457 31.5088 30.98 30.5744 30.98 29.4218V21.4915" stroke="#0079C1" stroke-width="2.08692" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default function UploadResume({ handleNext, handleBack, job_id, settingCandidateData, candLoader, profileStrength, candidateData, setIsFillup }) {
  const [uploadedResume, setUploadedResume] = useState("");
  
  const fileUploadRef = useRef("");
  const [uploadLoader, setUploadLoader] = useState(false);
  const [isResumeUpload, setIsResumeUpload] = useState(false);
  const [resumeList, setResumeList] = useState([]);
  const [resumeId, setResumeId] = useState();
  const [resumeListLoader, setResumeListLoader] = useState(false);
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const alertContext = useAlert();
  const [openCxoModal, setOpenCxoModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  
  const getResumeList = () => {
    setResumeListLoader(true);
    getResumeListById()
      .then((response) => {
        if (response && response.success) {
          setResumeList(response.response.data[0]);
          setResumeId(response.response.data[0]?.id);
          setResponseMessage("");
        }
      })
      .finally(() => setResumeListLoader(false));
  };

  useEffect(() => {
    getResumeList();
    settingCandidateData();
  }, [isResumeUpload]);

  // Apply General Job
  const applyingtoJob = () => {
    const formData = new FormData();

    if (!!resumeId) {
      formData.append("resume", resumeId);
    }

    candidateRegisterFlow("resume", job_id, formData)
      .then((response) => {
        if (response && response.success) {
          handleNext();
          
          //gtag code for complete registration - don't remove
          window.dataLayer.push({
            'event': 'candidateRegistrationStepperFinalStepComplete'
          });
          //gtag code ended
          //esk
          window.esk('track', 'candidateRegistrationStepperFinalStepComplete');
          //end esk

          if (job_id) {
            //gtag code for successful job application - don't remove
            window.dataLayer.push({
              'event': 'successful_application',
              'regMethod': 'from_stepper'
            });
            //gtag code ended
          }


        } else {
          // setResponseMessage(response.errors[0]?.message);
          alertContext.setResponseMessage(response.errors[0]?.message || 'You have already applied for this job.');
          alertContext.setOpenErrorAlert(true);
        }
      })
      .finally(() => setLoading(false), setResponseMessage(""));
  }

  // CXO
  const [sendRequestLoading, setSendRequestLoading] = useState(false)
  const [isRequestSucceed, setIsRequestSucceed] = useState(false)

  const postCxoRequest = () => {
    setSendRequestLoading(true)
    const formData = new FormData();
    if (uploadedResume) {
      formData.append("resume", uploadedResume);
    } else if(!!resumeId) {
      formData.append("resume", resumeId);
    }
    formData.append('job_id', job_id)

    postCxoEligibility(formData)
      .then((response) => {
        if (response && response.success) {
          setIsRequestSucceed(true)
        }
      }).finally(() => setSendRequestLoading(false))
  }

  // Handle Submit Button
  const handleSUbmit = () => {
    setLoading(true);
    if (resumeId === undefined) {
      setLoading(false)
      return setResponseMessage([`Please select a Resume`])
    }

    if (!!resumeId) {
      setIsFillup((prev) => ({ ...prev, resume: true }))
    }

    if (job_id && profileStrength < process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY) {
      setLoading(false);
      return setResponseMessage([`Your profile strength is below ${process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY}. Please complete all the steps to apply for this job.`])
    } else {
      getJobCxoOrNot(job_id)
        .then((response) => {
          if (response && response.response?.data) {
            if (job_id && candidateData?.is_cxo_eligible == 0) {
              setLoading(false);
              return setOpenCxoModal(true);
            } else {
              applyingtoJob()
            }
          } else {
            applyingtoJob()
          }
        })
    }

    const ip = fetchIpAddress();
    const platform = "web";
    const stepNo = 6;

    stepperStepInfo(stepNo, ip, platform).then((response) => {
      if (response && response.success) {
        setLoading(false);
      }
    })
  };

  /**
   * Get the file name from the link of the resume
   * @param {*} link
   * @returns filename
   */
    function fileName(link) {
      if (typeof link !== "string" || !link) {
        return ""; 
      }
      let resumeReverse = link
        .split("")
        .reverse()
        .join("");
      let reverseArray = resumeReverse.split("/");
      let filename = reverseArray[0]
        .split("")
        .reverse()
        .join("");
      return filename;
    }


  /**
   * This function will upload the resume
   * @param {file} e
   */
  //Upload Resume
  const bearerToken = Cookies.get("token");
  const handleSubmission = (file) => {
    setUploadedResume(file)
    setUploadLoader(true);

    const validTypes = [
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/pdf' // .pdf
    ];
    const maxSize = 10 * 1024 * 1024;
  
    // Check file type and size
    if(!validTypes.includes(file.type) && file.size > maxSize) {
      alertContext.setResponseMessage("Only DOC, DOCX, and PDF files are allowed And File size exceeds 10 MB!");
      setUploadLoader(false);
      alertContext.setOpenErrorAlert(true);
      return;
    }

    if (!validTypes.includes(file.type)) {
      alertContext.setResponseMessage("Only DOC, DOCX, and PDF files are allowed!");
      setUploadLoader(false);
      alertContext.setOpenErrorAlert(true);
      return;
    }
  
    if (file.size > maxSize) {
      alertContext.setResponseMessage("File size exceeds 10 MB!");
      setUploadLoader(false);
      alertContext.setOpenErrorAlert(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    fetch(
      `${process.env.REACT_APP_API_SERVER_BASE_URL}/candidate/resume/${candidateData.id}/upload`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + bearerToken,
        },
        body: formData,
      }
    )
      .then(async (res) =>  {
        let response = await res.json();
        if (response && response.success) {
          alertContext.setResponseMessage("Resume Uploaded Successfully!");
          setUploadLoader(false);
          setIsResumeUpload((prev) => !prev);
          alertContext.setOpenAlert(true);
          fileUploadRef.current.value= "";
          setResponseMessage("");
          // settingCandidateData();
        } else {
          alertContext.setResponseMessage(response.errors[0]['message'][0])
          alertContext.setOpenErrorAlert(true);
          setUploadLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // delete resume actions
  const handleOpen = (resumeId) => {
    setOpenDeleteModal(true);
    setSelectedResumeId(resumeId);
  };

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  /**
 * Delete Resume
 * @param {int} candidateId
 * @param {int} resumeId
 */
  const handleDeleteClick = (candidateId, resumeId) => {
    deleteResume(candidateId, resumeId).then((response) => {
      setResumeListLoader(true)
      if (response) {
        alertContext.setResponseMessage(response.response.message);
        alertContext.setOpenAlert(true);
        setResumeListLoader(false);
        setOpenDeleteModal(false);
        setIsResumeUpload((prev) => !prev);
        // settingCandidateData();
      } else {
        alertContext.setResponseMessage("Something went wrong!");
        alertContext.setOpenErrorAlert(true);
        setResumeListLoader(false)
      }
    });
  };

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Typography align="left" variant="h6" sx={{ fontWeight: 600, fontSize: "16px", mb: 1 }}>
            Resume
        </Typography>
      </Box>

      <Box sx={{mx: -6}}>
          <Divider />
      </Box>

      {candLoader ? (
        <div className="mt-3 col-lg-12 col-md-12">
          <Skeleton variant="text" height={60} />
          <Skeleton variant="text" height={50} />
          <div className="text-left">
            <Skeleton variant="text" />
          </div>
          <div className="mt-3 text-left">
            <Skeleton variant="rounded" height={120} />
          </div>
          <div className="text-left">
            <Skeleton variant="text" width={80} height={50} />
          </div>
        </div>
      ) : (
        <Box sx={{marginX: {xs: -3, sm: 0}}}>
          <form onSubmit={(e) => [e.preventDefault(), handleSUbmit()]}>
            {responseMessage ? (
              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                {responseMessage?.map((item, index) => {
                  return (
                    <center key={index}>
                      <small style={{ color: "red" }}> {responseMessage} </small>
                    </center>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            <Grid container spacing={2} mt={3}>
              <Grid item md={6} xs={12}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                  <Typography
                    sx={{ fontFamily: "Epilogue", fontWeight: 500, fontSize: {xs: "16px", md: "20px"}, lineHeight: "32px", color: "#72737C", pl: 3 }}
                  >
                    Upload Your Resume
                  </Typography>

                  {
                    uploadLoader ?
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '150px',
                        }}
                      >
                        <CircularProgress />
                      </Box>
                      :
                      <>
                        <input
                          type="file"
                          hidden
                          id="upload-resume"
                          ref={fileUploadRef}
                          accept=".doc,.docx,.pdf" //  Accept only pdf files as backend only support pdf
                          onChange={(e) => handleSubmission(e.target.files[0])}
                        />
                        <Box
                          mt={3}
                          sx={{
                            padding: "12px",
                            border: "2px dashed #0079C1",
                            borderRadius: "48px",
                          }}
                        >
                          <LoadingButton
                            variant="contained"
                            endIcon={<CloudUploadIcon />}
                            sx={{
                              width: "100%",
                              padding: {xs: "18px 5px", sm: "18px 10px"},
                              borderRadius: "48px",
                              display: "flex",
                              justifyContent: {xs: "flex-start", sm: "center"}, 
                              alignItems: "center",  
                              textAlign: {xs: "left", sm: "center"}, 
                              "& .MuiButton-endIcon": {
                                position: "absolute",
                                right: 16, 
                              },
                            }}
                            onClick={() => fileUploadRef.current.click()}
                          >
                            <Typography
                              sx={{
                                width: {xs: "200px", sm: "400px", lg: "320px", xl: "450px"}, 
                                overflow: "hidden", 
                                textOverflow: "ellipsis", 
                                whiteSpace: "nowrap", 
                                textAlign: "center",
                                fontFamily: "Epilogue",
                                fontWeight: 700,
                                fontSize: {xs: "16px", md: "19.15px"},
                                lineHeight: "19.15px",
                                textTransform: "capitalize",
                              }}
                            >
                              Upload Your Resume
                            </Typography>
                          </LoadingButton>
                        </Box>
                      </>
                  }

                  <Typography
                    sx={{fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: "#72737C", lineHeight: "21px", mt: 2, pl: 3 }}
                  >
                    Supported Formats: doc, docx, pdf, upto 10 MB
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                {resumeListLoader ?
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '150px',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                  :
                  <>
                    {(resumeList && resumeList !== null && resumeList !== undefined) ?
                      <Box sx={{paddingLeft: {xs: "0px", md: "40px"}}}>
                        <Box sx={{display: "flex", gap: 1, marginTop: {xs: "30px", md: "75px"}}}>
                          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 12.1016C24 18.7289 18.6274 24.1016 12 24.1016C5.37258 24.1016 0 18.7289 0 12.1016C0 5.47414 5.37258 0.101562 12 0.101562C18.6274 0.101562 24 5.47414 24 12.1016ZM16.8364 8.46517C17.1878 8.81664 17.1878 9.38649 16.8364 9.73792L10.8364 15.7379C10.4849 16.0894 9.91512 16.0894 9.5636 15.7379L7.1636 13.3379C6.81214 12.9864 6.81214 12.4167 7.1636 12.0652C7.51507 11.7137 8.08493 11.7137 8.4364 12.0652L10.2 13.8287L12.8818 11.147L15.5636 8.46517C15.9151 8.1137 16.4849 8.1137 16.8364 8.46517Z" fill="#15A449"/>
                          </svg>
                          <Typography sx={{fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", lineHeight: "24px", color: "#0079C1"}}>
                            Resume Uploaded Successfully
                          </Typography>
                        </Box>
        
                        <Box sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 2}}>
                          <a
                            href={resumeList.resume_full_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primay"
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              lineHeight: "18px",
                              color: "#72737C",
                              marginLeft: "30px",
                              overflow: "hidden", 
                              textOverflow: "ellipsis", 
                              whiteSpace: "nowrap", 
                            }}
                          >
                            {fileName(resumeList.resume_link)}
                          </a>
                          <img
                            className="pl-2"
                            style={{ width: "40px", height: "40px", cursor: "pointer" }}
                            src="/v2/icons/trash_resume_stepper.svg"
                            alt=""
                            onClick={() =>
                              handleOpen(resumeList.id)
                            }
                          />
                        </Box>
                      </Box>
                      :
                      <NoResumeUploaded />
                    }
                  </>
                }
              </Grid>
            </Grid>


            {/* button section */}
            <Box sx={{mx: {xs: -3, sm: -6}, paddingTop: "40px"}}>
                <Divider />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 4 }}>
                <Button 
                    variant="outlined" 
                    // disabled 
                    sx={{ 
                        mr: 1, 
                        padding: {xs: "10px 16px"}, 
                        minWidth: {sm: "102px"}, 
                        color: "#0079C1", 
                        fontFamily: "Epilogue", 
                        fontWeight: 600, 
                        fontSize: "14px", 
                        lineHeight: "16.5px", 
                        display: "flex", 
                        alignItems: "center", 
                        gap: 1, 
                        textTransform: "capitalize", 
                        // '&.Mui-disabled': {color: "#0079C1", borderColor: "#0079C1",} 
                        }}
                        onClick={handleBack}
                    >
                        <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.64706 1.10156L2 7.10156L7.64706 13.1016" fill="white"/>
                        <path d="M7.64706 1.10156L2 7.10156M2 7.10156L7.64706 13.1016M2 7.10156L26 7.10156" stroke="#0079C1" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Back
                </Button>
                <Box sx={{ display: "flex" }}>
                    <LoadingButton
                        sx={{ ml: {xs: 1, md: 2}, padding: {xs: "10px 16px"}, minWidth: {sm: "102px"}, color: "#FFFDFD", fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "20.5px", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize" }}
                        variant="contained"
                        loading={loading}
                        type="submit"
                    >
                        Finish
                        <svg width="28" height="15" viewBox="0 0 28 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.3529 13.1016L26 7.10156M26 7.10156L20.3529 1.10156M26 7.10156L2 7.10156" stroke="white" stroke-width="2.18" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </LoadingButton>
                </Box>
            </Box>  

          </form>
        </Box>
      )}

      <UpdateDeleteConfirmationModal
        open={openDeleteModal}
        onClose={handleClose}
        deleteItem={() => handleDeleteClick(candidateData.id, selectedResumeId)}
      />

      <ApplyCxoModal postCxoRequest={postCxoRequest} jobId={job_id} open={openCxoModal} setOpen={setOpenCxoModal} handleNext={handleNext} isRequestSucceed={isRequestSucceed} sendRequestLoading={sendRequestLoading} />
    </>
  );
}