import React, { useEffect, useState } from 'react';
import { Box, Grid, Link, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // or use CircleIcon for outlined bullet

function RightArrowBlack() {
    return (
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5505_67637)">
                <path d="M1.52744e-08 1.28089C2.00191e-08 1.67877 0.144679 1.98839 0.410174 2.25244C2.46927 4.30889 4.52765 6.36606 6.5853 8.42395C6.68513 8.52378 6.68368 8.57261 6.5853 8.67172C4.53874 10.711 2.49472 12.7527 0.453225 14.7966C0.0625772 15.1868 -0.0893473 15.6484 0.0853589 16.1794C0.24198 16.6539 0.585242 16.9516 1.07898 17.0475C1.52858 17.1329 1.92611 17.0041 2.2502 16.6804C4.6346 14.2974 7.01778 11.9134 9.39977 9.52825C9.99804 8.92926 9.99659 8.16569 9.39977 7.56705C7.01513 5.18266 4.63146 2.79911 2.24875 0.416399C1.50073 -0.331619 0.32843 -0.0295895 0.0332746 0.984647C0.00144403 1.09171 0.0104896 1.19914 1.52744e-08 1.28089Z" fill="#72737C" />
            </g>
            <defs>
                <clipPath id="clip0_5505_67637">
                    <rect width="17.0713" height="9.84685" fill="white" transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}

function RightArrowBlue() {
    return (
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5505_67631)">
                <path d="M1.52744e-08 1.28089C2.00191e-08 1.67877 0.144679 1.98839 0.410174 2.25244C2.46927 4.30889 4.52765 6.36606 6.5853 8.42395C6.68513 8.52378 6.68368 8.57261 6.5853 8.67172C4.53874 10.711 2.49472 12.7527 0.453225 14.7966C0.0625772 15.1868 -0.0893473 15.6484 0.0853589 16.1794C0.24198 16.6539 0.585242 16.9516 1.07898 17.0475C1.52858 17.1329 1.92611 17.0041 2.2502 16.6804C4.6346 14.2974 7.01778 11.9134 9.39977 9.52825C9.99804 8.92926 9.99659 8.16569 9.39977 7.56705C7.01513 5.18266 4.63146 2.79911 2.24875 0.416399C1.50073 -0.331619 0.32843 -0.0295895 0.0332746 0.984647C0.00144403 1.09171 0.0104896 1.19914 1.52744e-08 1.28089Z" fill="#0079C1" />
            </g>
            <defs>
                <clipPath id="clip0_5505_67631">
                    <rect width="17.0713" height="9.84685" fill="white" transform="matrix(1.19249e-08 1 1 -1.19249e-08 0 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}

function RefundpolicyContent() {
    return (
        <Box sx={{ padding: '16px', marginBottom: 12 }}>
            {/* Title */}
            <Typography sx={{ color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Refund Policy
            </Typography>

            {/* Description */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "15px", fontFamily: "Poppins", color: "#424447" }}>
                Please read this refund policy ("refund policy") carefully before using{' '}
                <Link href="https://www.atb-jobs.com" target="_blank" rel="noopener noreferrer">
                    www.atb-jobs.com
                </Link>{' '}
                website ("website", "service") operated by atBJobs ("us", "we", "our").
            </Typography>

            {/* First Paragraph */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                All transactions for purchase of intangible products, pdf downloads, resource material, and packages are made through
                payment gateways that use SSL encryption. These payment gateways are safe and secure for using all types of credit cards and
                debit cards in different countries and your details are not stored during this process.
            </Typography>

            {/* Second Paragraph */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                Since your purchase is a digital product/package, it is deemed “used” after the transaction, and all purchases made on{' '}
                <Link href="https://www.atb-jobs.com" target="_blank" rel="noopener noreferrer">
                    www.atb-jobs.com
                </Link>{' '}
                are non-refundable or exchangeable. Since the products/packages made available here are intangible, there is a strict no refund
                policy.
            </Typography>

            {/* Final Note */}
            <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                ATB Jobs reserves the right to amend any information, including but not limited to prices, technical specifications, terms of
                purchase, and product or service offerings without prior notice.
            </Typography>
        </Box>
    )
}

function PrivacyPolicyContent() {
    return (
        <Box sx={{ padding: '16px' }}>
            {/* Title */}
            <Typography sx={{ color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                atBJobs Bangladesh Privacy Policy
            </Typography>

            {/* Introductory text */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "15px", fontFamily: "Poppins", color: "#424447" }}>
                The authority of atB Jobs Bangladesh has the right to make changes to this privacy policy at any time by notifying its users on this page and
                possibly within this Application and/or—as far as technically and legally feasible—sending a notice to users via any contact information available to us. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.
            </Typography>

            {/* Version Info */}
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 4 }}>
                <Typography variant="subtitle1" sx={{ maxWidth: "600px", padding: { xs: "8px 30px", sm: "8px 70px", md: "8px 70px", lg: "8px 90px" }, borderTopLeftRadius: "24px", borderBottomRightRadius: "24px", fontWeight: 700, backgroundColor: "#E5F5FF", textAlign: "center" }}>
                    Version 1.0.2 (Last Updated May 2nd, 2024)
                </Typography>
            </Box>

            {/* Children Section */}
            <Typography variant="h6" sx={{ color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Children
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                atBJobs.com is not directed to children under the age of 18 and does not knowingly collect information from children under the age of 18.
                We also refuse registration to children under the age of 18 (for the protection of under-age children).
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                We also refuse registration to children under the age of 18 (for the protection of under-age children).
            </Typography>

            {/* Information We Collect */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Information we collect:
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                atBJobs.com offers several mobile applications. atBJobs.com collects and uses information about you as described herein.
                The categories of personal information we collect:
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                The categories of personal information we collect:
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                We are atB-Jobs Bangladesh our goal is to collected the personal and professional information about you: identifiers, commercial information, biometric information, internet information, geolocation data, sensorial information, employment related information and inferred information.
                We will not collect additional personal information without notifying you.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                We will not collect additional personal information without notifying you.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                How we collect information: what are the sources of the personal information we collect?
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                We will collect your personal information, either directly or indirectly, from you when you use this Website.
                For example, you directly provide your personal information when you submit requests via any forms on this Website. You also provide personal information indirectly when you navigate this website, as personal information about you is automatically observed and collected. Finally, we may collect your personal information from third parties that work with us in connection with the Service or with the functioning of this Website and features thereof.
                How we use the information we collect: sharing and exposing of your personal information with third parties for a business purpose,
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                We may share the personal information we collect about you to a third party for business purposes. In that case, we may make a written agreement with such third party that requires the recipient to both keep the personal information confidential and not use it for any other purpose and those are necessary for the performance of the agreement.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                We may also share your personal information to third parties when you the permission, or authorize us to do so, in order to provide you with our service.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                To find out more about the purposes of the processing, please refer to the relevant section of this document.
                Definitions and legal references
            </Typography>

            {/* Personal Data */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Personal Data
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
            </Typography>

            {/* Usages Data */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Usage Data
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                Information is collected automatically through this Website, which can include: the IP addresses or domain names of the computers utilized by the Users who use this Website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer, the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Webpage) and the details about the path followed within the Website with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.
            </Typography>

            {/* Third-Party Tracking Technologies */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Third-Party Tracking Technologies
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                atB-Jobs Bangladesh uses the Analytics tracking technology to track visits to our website. It allows us to analyze the usage of our website and is merely for our internal use.
            </Typography>

            {/* Cookies and Other Tracking Technologies */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Cookies and Other Tracking Technologies
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                Some of our Web pages utilize "Cookies" and other tracking technologies. A "Cookie" is a small text file that may be used, for example, to collect information about Website activity. Some ''Cookies'' and other technologies may serve to recall Personal Information previously indicated by a user. Most browsers allow you to control ''Cookies'', including whether or not to accept them and how to remove them. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but please note that if you choose to wipe or block your cookies, you will need to re-enter through your original user ID and password to accomplish access to certain parts of the Website. Our use of cookies and other tracking technologies allows us to improve our website and your web experience. Your IP address might be used to track your location to provide you with various location-based services and for our data analyzing purposes. We may also analyze information that does not carry personal information for aptitude and statistics.
            </Typography>

            {/* Security */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Security
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                We use lots of tools (encryption, passwords, and physical security and many more) to protect your personal information against unauthorized access and exposure.
            </Typography>

            {/* Opting out of E-Mail Notification Services */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Opting out of E-Mail Notification Services
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                We allow users to opt-out of having Email Notification Alerts sent to them at any time.
            </Typography>

            {/* Revision and updates */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Revision and updates
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                atB-Jobs Bangladesh have authority to revises and updates its privacy policy in regular bases and publishes changes to the policy at our website.
            </Typography>
        </Box>
    )
}

function TermsAndConditionsContent() {
    return (
        <Box sx={{ padding: '16px' }}>
            {/* Title */}
            <Typography sx={{ color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Terms and Conditions.
            </Typography>

            {/* Introductory text */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "15px", fontFamily: "Poppins", color: "#424447" }}>
                Please read this terms and conditions ("terms and conditions", "terms") carefully before using{' '}
                <Link href="https://www.atb-jobs.com" target="_blank" rel="noopener noreferrer">
                    www.atb-jobs.com
                </Link>{' '}
                website ("website", "service") operated by atBJobs ("us", "we", "our").
            </Typography>

            {/* Version Info */}
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", my: 4 }}>
                <Typography variant="subtitle1" sx={{ maxWidth: "600px", padding: { xs: "8px 10px", sm: "8px 80px", md: "8px 30px", lg: "8px 80px" }, borderTopLeftRadius: "24px", borderBottomRightRadius: "24px", fontWeight: 700, backgroundColor: "#E5F5FF", textAlign: "center" }}>
                    Terms and Condition V1.0.2 (Last Updated May 2nd, 2024)
                </Typography>
            </Box>

            {/* Disclaimer Title */}
            <Typography variant="h6" sx={{ color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Disclaimer
            </Typography>

            {/* Disclaimer Text */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                Regarding the acquisition of personal information by a third party, our company assumes no responsibility in the following cases:
            </Typography>

            {/* Numbered Points */}
            <Box>
                <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 1, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                    (1) When a user discloses personal information to a specific company using the functions of this service or other means.
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 1, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                    (2) If the user is unexpectedly identified based on the user’s activity information or information entered into this service.
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 1, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                    (3) When a user provides personal information and that information is used in an external service linked from this service.
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: "30px", fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                    (4) If a third party other than the user obtains information that can identify an individual (ID, password, etc.).
                </Typography>
            </Box>

            {/* Handling of Personal Information */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Handling of Personal Information
            </Typography>

            {/* part 01 */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                (1) Acquisition, use, and provision of personal information
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                Regarding the acquisition, use, and provision of personal information, we will handle it appropriately within the scope of the purpose of use described in "Handling of Personal Information", taking into account the content and scale of the information service, etc. Our company will not use acquired or entrusted personal information for any purpose other than the purpose of use as defined by the terms and conditions agreed by users. If personal information is to be used for purposes beyond the intended use, the consent of the individual shall be obtained in advance.
            </Typography>

            {/* part 02  */}
            <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                (2)  Proper management of personal information
            </Typography>
            <List>
                <ListItem sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <FiberManualRecordIcon fontSize='small' sx={{ width: "10px", marginTop: 1, marginRight: 1 }} />
                    <ListItemText sx={{ fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}
                        primary="We will maintain a safety management system at an appropriate and reasonable level to prevent unauthorized access to personal information, loss, destruction, falsification, leakage, etc. of personal information."
                    />
                </ListItem>
                <ListItem sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <FiberManualRecordIcon fontSize='small' sx={{ width: "10px", marginTop: 1, marginRight: 1 }} />
                    <ListItemText sx={{ fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }} primary="Access to personal information will be limited to the minimum necessary number of officers and employees." />
                </ListItem>
                <ListItem sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <FiberManualRecordIcon fontSize='small' sx={{ width: "10px", marginTop: 1, marginRight: 1 }} />
                    <ListItemText sx={{ fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}
                        primary="When outsourcing the handling of personal information, we will conclude a confidentiality agreement and conduct necessary audits to ensure its effectiveness."
                    />
                </ListItem>
                <ListItem sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <FiberManualRecordIcon fontSize='small' sx={{ width: "10px", marginTop: 1, marginRight: 1 }} />
                    <ListItemText sx={{ fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}
                        primary="Our company respects the rights of individuals regarding personal information, and will respond to requests for disclosure, correction, or deletion of personal information through reasonable procedures."
                    />
                </ListItem>
            </List>

            {/* part 03 */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                (3) Our company may disclose confidential information to our company's related parties
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                Such as its own officers, employees, lawyers or tax accountants, consultants, and advisors, only to the extent necessary to provide this service or as specified in these Terms of Use. The information shall not be disclosed or leaked to any other third party without the prior written consent of the user company, unless disclosure is required by law or by order of a court or government agency and will not be used for purposes other than providing this service or other purposes outside the scope of these Terms of Use.
            </Typography>

            {/* part 04 */}
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                (4) Account Information Visibility
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                When you create an account on ATB Jobs Bangladesh and provide your full information and resume:
            </Typography>
            <List>
                <ListItem sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <FiberManualRecordIcon fontSize='small' sx={{ width: "10px", marginTop: 1, marginRight: 1 }} />
                    <ListItemText sx={{ fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}
                        primary="Your personal information, including your resume, will be visible to registered employers"
                    />
                </ListItem>
                <ListItem sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <FiberManualRecordIcon fontSize='small' sx={{ width: "10px", marginTop: 1, marginRight: 1 }} />
                    <ListItemText sx={{ fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}
                        primary="By creating an account, you explicitly agree that your information will be accessible to all employers using the ATB Jobs platform for recruitment purposes." />
                </ListItem>
            </List>

            {/* Intellectual property rights such as copyright and other property rights */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Intellectual property rights such as copyright and other property rights
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                You agree that all materials, products, and services provided on this website are the property of atBJobs, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the atBJobs's intellectual property in any way, including electronic, digital, or new trademark registrations. You grant atBJobs a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.
            </Typography>

            {/* details list of Intellectual property rights */}
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                1. The copyright of the deliverables resulting from the outsourced work delivered by the Company based on this agreement shall belong to the Company, and the Company shall provide the User with the right to use the work to the extent necessary for the User to use the service effectively.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                2. Of the constituent parts included in the deliverables, the copyright and all other intellectual property rights of existing components to which the Company has previously held rights or those that are commonly used in similar types of work belong to the Company.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                3. The Company shall be able to publicly announce that the Deliverables were created by itself. Users will in advance agree that the Company may use such deliverables (including those created by the User) to the extent necessary for atBJobs.com operated by the Company and for the advertising purpose of the Company.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                4. In addition to what is provided for in this article, if an invention, device, design, or other result (hereinafter referred to as an "invention, etc.") is generated in the process of performing commissioned work, a patent or utility model registration for the invention, etc., the right to receive design registration, etc., and the industrial property rights and other intellectual property rights (including rights related to know-how, etc.) acquired based on such rights shall belong to the party to which the person who made the invention, etc.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                5. With respect to materials, data, etc. provided by the User to the Company necessary for carrying out the commissioned work, the User shall not be responsible for any third party's copyrights, patent rights, design rights, or any other intellectual property rights. You warrant that you do not infringe on any proprietary rights.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                6. When using this service and using materials belonging to our company, the materials will not be transferred, but the materials will be provided to the service used by the user. Users shall be prohibited the secondary use of the materials.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                7. If a third party makes a complaint, demand, injunction, or any other claim due to infringement of intellectual property rights when the user uses the product, the user and the company will discuss and resolve the matter.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                8. Intellectual property rights for photographs, logos, documents, etc. provided by information posters to our company that the information poster has previously held intellectual property rights to (hereinafter referred to as ""information poster property rights"") belongs to the person. However, intellectual property rights related to job advertisements and each content included in job advertisements (including information, documents, photographs, images, videos, etc.) belong to our company, excluding property rights of information posters. The author shall not exercise any legal rights in this regard.
            </Typography>


            {/* The user and the job publishing  */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                The user and the job publishing Company promise to the other party that they will not engage in any of the following acts either themselves or by using a third party.
            </Typography>
            {/* details list of Intellectual property rights */}
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                1. Violent demands
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                2. Unreasonable demands beyond legal responsibility
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                3. Acts of threatening behavior or using violence regarding transactions.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                4. Spreading rumors, using fraudulent means or force to damage the credibility of the other party, or disrupting the other party's business.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                5. Other acts similar to the preceding items.
            </Typography>


            {/* The user company or our company may cancel all or part of this agreement in writing without any notice if the other party falls under any of the following items or is reasonably deemed to fall under any of the following: shall be able to do so.  */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                The user company or our company may cancel all or part of this agreement in writing without any notice if the other party falls under any of the following items or is reasonably deemed to fall under any of the following: shall be able to do so.
            </Typography>
            {/* details list of Intellectual property rights */}
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                1. In the case of violation of paragraph 1 or the preceding paragraph
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                2. Against anti-social forces, such as when you or your officers, etc. engage in any kind of transaction with anti-social forces by investing, lending, providing funds or services, etc. to anti-social forces. If the person has a relationship where the person is recognized to be involved in the provision of funds, etc. or benefits, etc.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                3. Relationships in which the company or its officers, etc. are deemed to be using anti-social forces unfairly, such as for the purpose of seeking fraudulent profits for the company or a third party, or for the purpose of causing damage to a third party. If you have
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                4. If the person or his/her officers, etc. have a socially reprehensible relationship with antisocial forces.
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: "30px", marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                5. In other cases pursuant to each of the preceding items.
            </Typography>


            {/* -------------------- */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                If this Agreement is canceled pursuant to the provisions of the preceding paragraph, neither the User Company nor the Company shall be responsible for compensating the other party for any damage caused to the other party due to such cancellation, and the Company shall not be responsible for compensating the other party for any damages incurred as a result of such cancellation. It shall be possible to claim compensation.
            </Typography>


            {/* App Permission  */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                App permissions
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                The atBJobs app requires specific permissions to run on Android and requires access to certain systems within your device. The app can store your personalization data. A common reason for storage permissions is that this data helps users get a personalized app experience.
            </Typography>

            {/* About usage fees */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                About usage fees
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                The platform is free for all companies and job seekers until further notice issued by the Company.
            </Typography>

            {/* Conditions of use */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Conditions of use
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                By using this website, you certify that you have read and reviewed this agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. atbJobs only grants use and access of this website, its products, and its services to those who have accepted its terms.
            </Typography>

            {/* Privacy policy */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Privacy policy
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                By using this website, you certify that you have read and reviewed this agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. atbJobs only grants use and access of this website, its products, and its services to those who have accepted its terms.
            </Typography>

            {/* Age restriction */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Age restriction
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. atBJobs assumes no responsibility for liabilities related to age misrepresentation.
            </Typography>

            {/* User accounts */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                User accounts
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly.
                We reserve all rights to terminate accounts or remove content and cancel orders at our sole discretion.
            </Typography>

            {/* Applicable law */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Applicable law
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.
                If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly.
                We reserve all rights to terminate accounts or remove content and cancel orders at our sole discretion.
            </Typography>

            {/* Indemnification */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Indemnification
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                You agree to indemnify atBJobs and its affiliates and hold atBJobs harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.
            </Typography>

            {/* Indemnification */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                Limitation on liability
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2, fontWeight: 400, fontSize: "14px", fontFamily: "Poppins", color: "#424447" }}>
                atBJobs is not liable for any damages that may occur to you as a result of your misuse of our website.
            </Typography>

            {/* ------------ */}
            <Typography variant="h6" sx={{ mt: 6, color: "#0079C1", marginBottom: 2, fontWeight: 700, fontSize: "18px", fontFamily: "Epilogue" }}>
                atBJobs reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between atBJobs and the user, and this supersedes and replaces all prior agreements regarding the use of this website.
            </Typography>
        </Box>
    )
}

function PolicyPage() {
    const location = useLocation();
    const history = useHistory();
    const [selectedPolicy, setSelectedPolicy] = useState('');

    const policies = [
        { id: 1, title: 'Terms and Conditions', link: "/terms-conditions" },
        { id: 2, title: 'Privacy Policy', link: "/privacy-policy" },
        { id: 3, title: 'Refund Policy', link: "/refund-policy" },
    ];

    useEffect(() => {
        setSelectedPolicy(location.pathname)
    }, [location.pathname])

    const renderPolicyContent = () => {
        switch (selectedPolicy) {
            case '/terms-conditions':
                return (
                    <TermsAndConditionsContent />
                );
            case '/privacy-policy':
                return (
                    <PrivacyPolicyContent />
                );
            case '/refund-policy':
                return (
                    <RefundpolicyContent />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Box sx={{ backgroundColor: "#0079C1", height: "300px" }}>
            </Box>
            <Grid container spacing={4} sx={{ marginTop: "-260px", padding: { xs: "12px", sm: "30px", md: "30px 50px 50px 30px", lg: "30px 80px 80px 80px" } }}>
                {/* Sidebar */}
                <Grid item xs={12} md={4} lg={3}>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2, border: "1px solid #C4C4C4", boxShadow: 2 }}>
                        <List sx={{ padding: { xs: "30px 0px 30px 0px", sm: "30px 0px 30px 0px", md: "30px 0px 150px 0px" } }}>
                            {policies.map((policy) => (
                                <ListItem
                                    button
                                    key={policy.id}
                                    selected={selectedPolicy === policy.link}
                                    onClick={() => { setSelectedPolicy(policy.link); history.push(policy.link) }}
                                    sx={{
                                        marginY: 3,
                                        fontFamily: "Epilogue",
                                        fontWeight: "700",
                                        fontSize: "16px",
                                        backgroundColor: policy.link === location.pathname ? "#E5F5FF" : "",
                                        color: policy.link === location.pathname ? "#0079C1" : "",
                                    }}
                                >
                                    <ListItemText primary={policy.title} primaryTypographyProps={{ sx: { paddingLeft: 1, fontWeight: 700, fontSize: "16px", fontFamily: "Epilogue" } }} />
                                    {policy.link === location.pathname ? <RightArrowBlue /> : <RightArrowBlack />}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>

                {/* Content Area */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper elevation={3} sx={{ padding: { xs: 1, sm: 3 }, borderRadius: 2, boxShadow: 2 }}>
                        {renderPolicyContent()}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default PolicyPage;
