import React, { useContext, useState } from "react";
import { Box, Card, ClickAwayListener, Divider, Fade, Grid, styled, Tooltip, tooltipClasses, Typography, Alert } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkIcon from '@mui/icons-material/Link';
import { followCompany, unFollowCompany } from "../../../../fetchAPI";
import SingleEmployerInformation from "./SingleEmployerInformation";
import GlobalLoader from "../../GlobalLoader";
import { Link } from 'react-router-dom'
import EmployerProfilePicture from "../../EmployerComponents/EmployerProfilePicture";
import CandidateContextProvider, { CandidateContext } from "../../../context/CandidateContext";
import { UserContext } from "../../../context/UserContext";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#00A264',
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

function AboutCompanyCard(props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const { followEmployerIds, settingCandidateData, candidateData } = useContext(CandidateContext)
    const { userData } = useContext(UserContext)

    const [openAlert, setOpenAlert] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')

    const history = useHistory()

    const unSaveEmployer = (candId, empId) => {
        setSaveLoading(false);
        setLoading(true);

        unFollowCompany(candId, empId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData()
                    props.setResponseMessage(response.response.message)
                    props.setOpenAlert(true)
                } else {
                    // Handle failure, show error message or take appropriate action
                    console.log(response)
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const saveEmployer = (candId, empId) => {
        if (userData) {
            setLoading(false);
            setSaveLoading(true);

            followCompany(candId, empId)
                .then(response => {
                    if (response && response.success) {
                        settingCandidateData()
                        props.setResponseMessage(response.response.message)
                        props.setOpenAlert(true)
                    } else {
                        // Handle failure, show error message or take appropriate action
                        console.log(response)
                    }
                })
                .catch(error => {
                    // Handle errors, show error message or take appropriate action
                    console.log(error)
                })
                .finally(() => {
                    // setLoading(false);
                });
        }
        else {
            history.push('/login')
        }

    }

    // Parsing HTML from Rich Editor data
    const introHtml = props?.employerInfo?.intro;
    const parser = new DOMParser();
    const doc = parser.parseFromString(introHtml, 'text/html');
    const introPlainText = doc.body.textContent || "";
  
    const toggleExpansion = () => {
      setIsExpanded(!isExpanded);
    };

    return (
        <Card 
        sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: "10px",
            border: "1px solid #C4C4C4",
            boxShadow: 3,
            px: 3,
            pt: 4,
            pb: 3,
        }}
    >
        {/* Alert message */}
        {openAlert && (
            <Alert severity="success" onClose={() => setOpenAlert(false)}>
                {responseMessage}
            </Alert>
        )}
        {/* small screen */}
        <Box sx={{ display: {xs: 'flex', md: "none"}, flexDirection: "column", rowGap: 1}}>
            {props?.employerInfo?.profile_pic_base64 ? 
                <Tooltip title='Go to Profile'>
                    <Link
                        to={`/employer/profile/${props?.employerInfo?.slug}`}
                    >
                        <EmployerProfilePicture
                            profile_picture={props?.employerInfo?.profile_pic_base64}
                            height={64}
                            width={64}
                        />
                    </Link>
                </Tooltip>
                :
                <img
                    alt='Company Avatar'
                    src={'/images/employer/employer-building.png'}
                    width="64px"
                    height="64px"
                />
            }

            <Link
                to={`/employer/profile/${props?.employerInfo?.slug}`}
            >
                <Typography
                    variant='h5'
                    align='left'
                    sx={{
                        mt: 1,
                        fontFamily: "Epilogue",
                        fontWeight: 700,
                        fontSize: "18px",
                        lineHeight: "18.45px",
                        color: "#00457C",
                        '&:hover': {
                            textDecoration: 'underline'
                        },
                    }}
                >
                    {props?.employerInfo?.employer_name}
                </Typography>
            </Link>

            <Typography sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "14.7px",
                lineHeight: "22.05px",
                color: "#605C78"
            }}>
                {props?.employerInfo?.map_location ?
                    props?.employerInfo?.map_location
                    :
                    ""
                }
            </Typography>

            {
                userData?.user_role_id !== 3 ?
                    followEmployerIds.includes(props?.employerInfo?.id) ?
                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            onClick={() => unSaveEmployer(candidateData.id, props?.employerInfo?.id)}
                            sx={{
                                backgroundColor: '#E5F5FF',
                                borderRadius: '60px',
                                color: 'white',
                                padding: "10px 18px",
                                boxShadow: 0,
                                minWidth: '100px',
                                minHeight: "40px",
                                '&:hover': {
                                    backgroundColor: '#E5F5FF',
                                },
                            }}
                        >
                            {!loading && <span style={{ color: "#0079C1" }}>Unfollow</span>}
                        </LoadingButton>
                        :
                        <LoadingButton
                            loading={saveLoading}
                            variant='contained'
                            onClick={() => saveEmployer(candidateData.id, props?.employerInfo?.id)}
                            sx={{
                                backgroundColor: '#E5F5FF',
                                borderRadius: '60px',
                                color: 'white',
                                padding: "10px 18px",
                                boxShadow: 0,
                                minWidth: '100px',
                                minHeight: "40px",
                                '&:hover': {
                                    backgroundColor: '#E5F5FF',
                                },
                            }}
                        >
                            {!saveLoading && (
                                <>
                                    <AddCircleOutlineIcon style={{ color: "#0079C1", marginRight: "8px" }} />
                                    <span style={{ color: "#0079C1" }}>Follow</span>
                                </>
                            )}
                        </LoadingButton>
                    :
                    ""
            }
        </Box>

        {/* large screen */}
        <Box sx={{ display: {xs: "none", md: "flex"}, justifyContent: "space-between", alignItems: "center"}}>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: 1.5}}>
                {props?.employerInfo?.profile_pic_base64 ? 
                    <Tooltip title='Go to Profile'>
                        <Link
                            to={`/employer/profile/${props?.employerInfo?.slug}`}
                        >
                            <EmployerProfilePicture
                                profile_picture={props?.employerInfo?.profile_pic_base64}
                                height={64}
                                width={64}
                            />
                        </Link>
                    </Tooltip>
                    :
                    <img
                        alt='Company Avatar'
                        src={'/images/employer/employer-building.png'}
                        width="64px"
                        height="64px"
                    />
                }

                <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-evenly", rowGap: 0.5}}>
                    <Link
                        to={`/employer/profile/${props?.employerInfo?.slug}`}
                    >
                        <Typography
                            variant='h5'
                            align='left'
                            sx={{
                                fontFamily: "Epilogue",
                                fontWeight: 700,
                                fontSize: "18px",
                                lineHeight: "18.45px",
                                color: "#00457C",
                                '&:hover': {
                                    textDecoration: 'underline'
                                },
                            }}
                        >
                            {props?.employerInfo?.employer_name}
                        </Typography>
                    </Link>

                    <Typography sx={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14.7px",
                        lineHeight: "22.05px",
                        color: "#605C78"
                    }}>
                        {props?.employerInfo?.map_location ?
                            props?.employerInfo?.map_location
                            :
                            ""
                        }
                    </Typography>
                </Box>
            </Box>

            {
                userData?.user_role_id !== 3 ?
                    followEmployerIds.includes(props?.employerInfo?.id) ?
                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            onClick={() => unSaveEmployer(candidateData.id, props?.employerInfo?.id)}
                            sx={{
                                backgroundColor: '#E5F5FF',
                                borderRadius: '60px',
                                color: 'white',
                                padding: "10px 18px",
                                boxShadow: 0,
                                minWidth: '100px',
                                minHeight: "40px",
                                '&:hover': {
                                    backgroundColor: '#E5F5FF',
                                },
                            }}
                        >
                            {!loading && <span style={{ color: "#0079C1" }}>Unfollow</span>}
                        </LoadingButton>
                        :
                        <LoadingButton
                            loading={saveLoading}
                            variant='contained'
                            onClick={() => saveEmployer(candidateData.id, props?.employerInfo?.id)}
                            sx={{
                                backgroundColor: '#E5F5FF',
                                borderRadius: '60px',
                                color: 'white',
                                padding: "10px 18px",
                                boxShadow: 0,
                                minWidth: '100px',
                                minHeight: "40px",
                                '&:hover': {
                                    backgroundColor: '#E5F5FF',
                                },
                            }}
                        >
                            {!saveLoading && (
                                <>
                                    <AddCircleOutlineIcon style={{ color: "#0079C1", marginRight: "8px" }} />
                                    <span style={{ color: "#0079C1" }}>Follow</span>
                                </>
                            )}
                        </LoadingButton>
                    :
                    ""
            }
        </Box>

        {props?.employerInfo?.intro ? 
            <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, marginTop: 2}}>
                <Box sx={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "14.7px",
                    lineHeight: "22.05px",
                    color: "#605C78",
                    overflow: 'hidden',
                    maxHeight: isExpanded ? "none" : "88px",
                    transition: 'max-height 0.3s ease', 
                }}>
                    {
                        props?.employerInfo?.intro ? introPlainText : ""
                    }
                </Box>
                <Typography
                        sx={{
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        fontSize: "14.7px",
                        lineHeight: "22.05px",
                        color: "#00457C",
                        cursor: "pointer",
                    }}
                    onClick={toggleExpansion}
                >
                {isExpanded ? 'Read less' : 'Read more'}
                </Typography>
            </Box>
            :
            null
        }

    </Card>
    )
}

export function CompanyInformationCard(props) {
    return (
        <Card 
        sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: "10px",
            border: "1px solid #C4C4C4",
            boxShadow: 3,
        }}
    >
        <Typography sx={{
                p: 3,
                fontFamily: "Epilogue",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "18.45px",
                color: "#424447"
            }}>
            Company Information
        </Typography>
        <Divider />

        <Box sx={{p: 3}}>
            <Box sx={{mt: 0.5}}>
                <Typography sx={{fontFamily: "Epilogue", fontWeight: 600, fontSize: "16px", lineHeight: "19.2px", color: "#72737C"}}>Company Name</Typography>
                <Typography sx={{mt: 0.7, fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "22.4px", color: "#424447"}}>{props?.employerInfo?.employer_name}</Typography>
            </Box>
            <Box sx={{mt: 2.5}}>
                <Typography sx={{fontFamily: "Epilogue", fontWeight: 600, fontSize: "16px", lineHeight: "19.2px", color: "#72737C"}}>Industry</Typography>
                <Typography sx={{mt: 0.7, fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "22.4px", color: "#424447"}}>{props?.employerInfo?.type_of_business}</Typography>
            </Box>
            <Box sx={{mt: 2.5}}>
                <Typography sx={{fontFamily: "Epilogue", fontWeight: 600, fontSize: "16px", lineHeight: "19.2px", color: "#72737C"}}>Number of Employees</Typography>
                <Typography sx={{mt: 0.7, fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "22.4px", color: "#424447"}}>{props?.employerInfo?.number_of_employee}</Typography>
            </Box>
            <Box sx={{mt: 2.5}}>
                <Typography sx={{fontFamily: "Epilogue", fontWeight: 600, fontSize: "16px", lineHeight: "19.2px", color: "#72737C"}}>Founded</Typography>
                <Typography sx={{mt: 0.7, fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "22.4px", color: "#424447"}}>{props?.employerInfo?.established?.split("-")[0]}</Typography>
            </Box>
            <Box sx={{mt: 2.5}}>
                <Typography sx={{fontFamily: "Epilogue", fontWeight: 600, fontSize: "16px", lineHeight: "19.2px", color: "#72737C"}}>Number of Offices</Typography>
                <Typography sx={{mt: 0.7, fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "22.4px", color: "#424447"}}>{props?.employerInfo?.total_offices}</Typography>
            </Box>
            <Box sx={{mt: 2.5, mb: 1}}>
                <Typography sx={{fontFamily: "Epilogue", fontWeight: 600, fontSize: "16px", lineHeight: "19.2px", color: "#72737C"}}>Office Address</Typography>
                <Typography sx={{mt: 0.7, fontFamily: "Epilogue", fontWeight: 600, fontSize: "14px", lineHeight: "22.4px", color: "#424447"}}>{props?.employerInfo?.map_location}</Typography>
            </Box>

        </Box>
    </Card>
    )
}

function SocialCard(props) {
    const [responseMessage, setResponseMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [open, setOpen] = useState(false);
    const [onHover, setOnHover] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleOnHoverTooltipClose = () => {
        setOnHover(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 1500); // Close the tooltip after 2000 milliseconds (adjust as needed)
    };

    const handleIconClick = (link) => {
        const url = link ? link.toString() : '';

        if (url) {
            const hasHttp = url.startsWith('http://');
            const hasHttps = url.startsWith('https://');

            if (hasHttp || hasHttps) {
                // The string contains either 'http://' or 'https://'
                window.open(url, '_blank'); // Open in a new tab
            } else {
                // The string does not contain 'http://' or 'https://'
                window.open(`http://${url}`, '_blank'); // Open in a new tab
            }
        } else {
            console.error('Invalid URL: web is not defined');
        }
    };

    const socialLinkIconComponent = (socialLink) => {
        const socialIcons = {
            Facebook: (
                <FacebookIcon
                    fontSize="large"
                    sx={{
                        backgroundColor: '#3b5998',
                        color: 'white',
                        padding: 0.5,
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default', // Set cursor based on link availability
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            LinkedIn: (
                <LinkedInIcon
                    fontSize="large"
                    sx={{
                        backgroundColor: '#0a66c2',
                        color: 'white',
                        padding: 0.5,
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default',
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            Instagram: (
                <InstagramIcon
                    fontSize="large"
                    sx={{
                        background: 'linear-gradient(90deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)',
                        color: 'white',
                        padding: 0.5,
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default',
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            Twitter: (
                <TwitterIcon
                    fontSize="large"
                    sx={{
                        backgroundColor: '#3b5998',
                        color: 'white',
                        padding: 0.5,
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default', // Set cursor based on link availability
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            Youtube: (
                <YouTubeIcon
                    fontSize="large"
                    sx={{
                        backgroundColor: '#ED1E24',
                        color: 'white',
                        padding: 0.5,
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default',
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            Link: (
                <LinkIcon
                    fontSize="large"
                    sx={{
                        background: '#178bf4',
                        color: 'white',
                        padding: 0.5,
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default',
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
        };

        return socialIcons[socialLink.name] || null;
    };

    // Copy Profile Url
    function copyUrl() {
        const currentUrl = window.location.href;

        try {
            navigator.clipboard.writeText(currentUrl).then(() => {
                setResponseMessage('URL copied')
                setOpenAlert(true)
                // You can setResponseMessage and setOpenAlert here if needed
            }).catch((err) => {
                console.error("Error copying URL to clipboard:", err);
                // Handle the error if necessary
            });
        } catch (err) {
            console.error("Error copying URL to clipboard:", err);
            // Handle the error if necessary
        }
    }


    return (
        <Card 
        sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: "10px",
            border: "1px solid #C4C4C4",
            boxShadow: 3,
            p: 3,
        }}
        >
            <Typography sx={{
                fontFamily: "Epilogue",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "20.5px",
                color: "#1D1E1B"
            }}>
                Connect with {props?.employerName}
            </Typography>

            <Box sx={{display: "flex", flexWrap: "wrap", gap: 1, mt: 2.5}}>
                {props.socialLinks?.map((socialLink, index) => (
                    <React.Fragment key={index}>{socialLinkIconComponent(socialLink)}</React.Fragment>
                ))}

                {/* Tooltip handled for Before and After copy event */}
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <LightTooltip
                        onClose={handleTooltipClose}
                        open={open}
                        title={onHover ? "Copy URL" : "Copied"}
                        onMouseEnter={() => [setOnHover(true), handleTooltipOpen()]}
                    >
                        <LinkIcon
                            sx={{
                                background: '#178bf4',
                                color: 'white',
                                padding: 1,
                                width: "35px",
                                height: "35px",
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                            onClick={() => [copyUrl(), handleTooltipOpen(), handleOnHoverTooltipClose()]}
                        />
                    </LightTooltip>
                </ClickAwayListener>
            </Box>
        </Card>
    )
}

function SingleCompanyInformation(props) {
    const [openAlert, setOpenAlert] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    return (
        props.loading ? 
            <GlobalLoader height="70vh" />
            :
            <Box 
                sx={{
                    px: {xs: 0, md: 8},
                    filter: props?.isCxo === 0 || props?.isCxo === undefined ? 'none' : 'blur(8px)',
                    pointerEvents: props?.isCxo === 0 ? 'auto' : 'none'
                }}
            >
                {/* Alert message */}
                {openAlert && (
                    <Alert severity="success" onClose={() => setOpenAlert(false)} sx={{ position: 'fixed', top: 40, left: '50%', transform: 'translateX(-50%)', zIndex: 9999, width: 'auto', maxWidth: '90%', whiteSpace: 'nowrap', padding: '0 16px' }}>
                        {responseMessage}
                    </Alert>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <CandidateContextProvider>
                            <AboutCompanyCard employerInfo={props?.companyDetails} setOpenAlert={setOpenAlert} setResponseMessage={setResponseMessage} />
                        </CandidateContextProvider>
                        <SingleEmployerInformation loading={props?.loading} employerInfo={props?.companyDetails}  />
                        <Box sx={{display: {xs: "block", md: "none"}}}>
                            <SocialCard socialLinks={props?.companyDetails?.social_networks} employerName={props?.companyDetails?.employer_name} />
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={3} sx={{display: {xs: "none", md: "block"}}}>
                        <CompanyInformationCard employerInfo={props?.companyDetails} />
                        <SocialCard socialLinks={props?.companyDetails?.social_networks} employerName={props?.companyDetails?.employer_name} />
                    </Grid>
                </Grid>
            </Box>
    )
}

export default SingleCompanyInformation
